import React from 'react';
import '../BottomNavScreen/index.css'
import { LoadContent, NavDiv, NavHome, MainContainer, NavSearch, NavCustomer, NavSettings, NavBulkOperations, NavLabel, NavTransactions, NavStockOperation, NavUnpaidSTB } from './styled';
import {
    MdHome,
    MdSearch,
    MdSettings,
    MdPermIdentity,
    MdPersonAdd,
    MdTransferWithinAStation,
    MdShoppingBasket,
    MdCancel
} from 'react-icons/md';
import { IoIosCloseCircle } from "react-icons/io";
import DashBoard from '../dashboard';
import Search from '../CustomerSearch';
import BulkOperations from '../BulkOperations';
import CustomerDetails from '../CustomerDetails';
import CreateCustomerForm from '../CreateCustomer'
import Transactions from '../Transactions';
import API from '../../Api';
import UnmappedSTB from '../UnmappedSTB';
import AddPackPage from '../CustomerAddPackage';
import Credit from '../Transactions/CreditTransactions'
import Debit from '../Transactions/DebitTransactions'
import Notifications from '../Transactions/NotifiactionsTransactions'
import Profile from '../Transactions/Profile';
import ActivityLog from '../Transactions/ActivityLog';
// import StockOperation from '../StockOperation';
import StockOperation from '../InStock';
import STBCreation from '../InStock/STBCreation';
import STBTransfer from '../InStock/STBTransfer';

// import UnpaidSTB from "../UnpaidSTB"

import { FaFastForward } from 'react-icons/fa';

import STBReturns from '../InStock/STBReturns';
import CustomerTransfer from '../InStock/CustomerTransfer';
import FullDataProcess from '../fullDataProcess';
import Report from '../Transactions/Report';
import CreateCustomerWithSTB from '../InStock/CreateCustomerWithSTB';
import BulkCustomerCreation from '../Transactions/BulkCustomerCreation';
import BulkPackAddandRemove from '../InStock/BulkPackAddandRemove';
import GeneralValues from '../../GobelElements/Values';
import MonthlyLedger from '../Transactions/monthlyLeger';
import AllStb from '../Transactions/AllStb';

import Drawer from 'react-drag-drawer';

import { Button, ButtonGroup, Container, ButtonToolbar, Jumbotron, Card } from 'react-bootstrap';

// import STBCreation from '../StockOperation/STBCreation';
export default class index extends React.Component {

    constructor(props) {
        console.log("version 4.7.1");
        super(props);
        this.state = {

            content: "dashboard",
            dashboardNavColor: GeneralValues.bottomNav,
            searchNavColor: "#ffffff",
            createCustomerNavColor: "#ffffff",
            transactionsNavColor: "ffffff",
            settingsNavColor: "#ffffff",
            stockOperationColor: "#ffffff",
            specifyUserDetails: {},
            specifyUnmappedSTB: {},
            customerNo: "",
            unmappedSTBNO: "",
            searchText: "",
            searchQuery: "",
            lcoID: localStorage.getItem("userID"),
            lcoPassword: localStorage.getItem("userPassword"),
            domainName: localStorage.getItem("domain"),
            pageNo: 1,
            tableData: [],
            isShowStock: localStorage.getItem("isShowStock"),
            advtURL: "",
            isShowAdvertisementDialog: false,
            advtDuration: 0,
            isShowCancelBtn: false,
            startAndEndDate: {},
            isShowNotification: false,
            notificationDataList: [],
        }
        console.log("isShow", localStorage.getItem("isShowStock"), this.state.isShowStock);

    }
    componentDidMount() {
        debugger;
        this.setupBeforeUnloadListener();
    }
    // componentWillUnmount(){
    //     this.setState({searchQuery:""});
    //     console.log("vera level",this.state.searchQuery);
    // }
    hindAdvertisementDialog() {
        debugger;
        this.setState({ isShowAdvertisementDialog: false });
    }
    componentWillMount() {
        this.getADVT()
    }
    // componentWillUnmount(){
    //     clearTimeout(this.idleTimer);

    //     window.removeEventListener("mousemove", this.resetTimer, false);
    //     window.removeEventListener("mousedown", this.resetTimer, false);
    //     window.removeEventListener("keypress", this.resetTimer, false);
    //     window.removeEventListener("touchmove", this.resetTimer, false);
    //     window.removeEventListener("onscroll", this.resetTimer, false);
    // }
    resetTimer = () => {
        // Do something

        clearTimeout(this.idleTimer);
        if (window.location.pathname != "/login") {
            this.idleTimer = setTimeout(() => {

                localStorage.removeItem("userID");
                window.location.href = "/login";
                debugger;

            }, 600000);
        }


    }
    setupBeforeUnloadListener = () => {

        window.addEventListener("mousemove", this.resetTimer, false);
        window.addEventListener("mousedown", this.resetTimer, false);
        window.addEventListener("keypress", this.resetTimer, false);
        window.addEventListener("touchmove", this.resetTimer, false);
        window.addEventListener("onscroll", this.resetTimer, false);
    };




    getADVT() {

        const { lcoID, lcoPassword, domainName } = this.state;
        API.getAdvt(lcoID, lcoPassword, domainName)
            .then((response) => response.json())
            .then((responsejson) => {
                console.log("responser from advt", responsejson);
                if (responsejson.url) {
                    this.state.advtURL = responsejson.url
                    this.setState({ advtURL: responsejson.url, isShowAdvertisementDialog: true, advtDuration: parseInt(responsejson.duration) });

                }
                let myInterval = setInterval(() => {
                    this.setState({ advtDuration: this.state.advtDuration - 1 })
                    if (this.state.advtDuration == 0) {
                        clearInterval(myInterval);
                        this.setState({ isShowCancelBtn: true })
                    }
                }, 1000)
                this.getNotification();


            }).catch((error) => {
                console.log("Error in advt", error);
            });

    }
    getNotification() {
        const { lcoID, lcoPassword } = this.state;
        API.getNoficationForPop(lcoID, lcoPassword)
            .then(res => res.json())
            .then(resJson => {


                if (resJson.length) {
                    let data = resJson;
                    data = data.concat(this.state.notificationDataList);
                    this.setState({ notificationDataList: data, isShowNotification: true });
                }
                setTimeout(() => {
                    this.getNotification();
                }, 10000)

            })
    }
    showTheContent(content) {
        switch (content) {
            case "dashboard":
                return <DashBoard
                    moveToCreateCustomerForm={this.moveToCreateCustomerForm.bind(this)}
                    moveToCustomerDetails={this.moveToCustomerDetails.bind(this)}
                    moveToSearchWithQuery={this.moveToSearchWithQuery.bind(this)}
                    onClickProfile={this.onClickProfile.bind(this)}
                    moveToUnPaid={this.moveToUnPaid.bind(this)}
                    onClickNotifications={this.onClickNotifications.bind(this)}
                    moveToUnmappedSTB={this.moveToUnmappedSTB.bind(this)}
                    moveToAllSTB={this.moveToAllSTB.bind(this)}
                />;
            case "search":
                return <Search
                    searchQuery={this.state.searchQuery}
                    moveToCustomerDetails={this.moveToCustomerDetails.bind(this)}
                    moveToDashBoard={this.moveToDashBoard.bind(this)}
                />;
            case "bulkoperations":
                return <BulkOperations
                    moveToDashBoard={this.moveToDashBoard.bind(this)}
                    moveToCustomerDetails={this.moveToCustomerDetails.bind(this)}
                />;
            // case "UnpaidSTB":
            //     return <UnpaidSTB/>;
            case "customerdetails":
                return <CustomerDetails
                    moveToDashBoard={this.moveToDashBoard.bind(this)}
                    specifyUserDetails={this.state.specifyUserDetails}
                    moveToBackToCustomerSearch={this.moveToBackToCustomerSearch.bind(this)}
                    moveToCustomerDetails={this.moveToCustomerDetails.bind(this)}
                />;
            case "unmappedSTB":
                return <UnmappedSTB
                    onMoveToTransactions={this.onMoveToTransactions.bind(this)}
                    moveToCreateCustomerForm={this.moveToCreateCustomerForm.bind(this)}
                    moveToDashBoard={this.moveToDashBoard.bind(this)}
                    moveToCustomerDetails={this.moveToCustomerDetails.bind(this)}
                />;
            // case "stockoperation":
            // return <StockOperation 
            // moveToSTBCreation={this.moveToSTBCreation.bind(this)}
            // />;
            case "createCustomerForm":
                return <CreateCustomerForm
                    specifyUnmappedSTB={this.state.specifyUnmappedSTB}
                    moveToBackToUnmappedSTB={this.moveToBackToUnmappedSTB.bind(this)}
                    moveToAddPackPage={this.moveToAddPackPage.bind(this)}
                    moveToDashBoard={this.moveToDashBoard.bind(this)}
                    moveToCustomerDetails={this.moveToCustomerDetails.bind(this)}
                />
            case "addpackpage":
                return <AddPackPage
                    backToUnMappedSTB={this.backToUnMappedSTB.bind(this)}
                    customerNo={this.state.customerNo}
                    STBNo={this.state.unmappedSTBNO}
                    moveToDashBoard={this.moveToDashBoard.bind(this)}
                    moveToCustomerDetails={this.moveToCustomerDetails.bind(this)}
                />
            // case "stockoperation":
            // return <AddPackPage
            //     backToUnMappedSTB={this.backToUnMappedSTB.bind(this)}
            //     customerNo={this.state.customerNo}
            //     STBNo={this.state.unmappedSTBNO} />
            case "transactions":
                return <Transactions
                    onClickCredit={this.onClickCredit.bind(this)}
                    onClickDebit={this.onClickDebit.bind(this)}
                    onClickNotifications={this.onClickNotifications.bind(this)}
                    onClickProfile={this.onClickProfile.bind(this)}
                    onClickActivityLog={this.onClickActivityLog.bind(this)}
                    moveToUnmappedSTB={this.moveToUnmappedSTB.bind(this)}
                    moveToReport={this.moveToReport.bind(this)}
                    moveToBulkCustomerCreation={this.moveToBulkCustomerCreation.bind(this)}
                    moveToMonthlyLedger={this.moveToMonthlyLedger.bind(this)}
                    moveToDashBoard={this.moveToDashBoard.bind(this)}
                    moveToCustomerDetails={this.moveToCustomerDetails.bind(this)}
                    moveToAllSTB={this.moveToAllSTB.bind(this)}
                />
            case "credit":
                return <Credit onMoveToTransactions={this.onMoveToTransactions.bind(this)} tableData={this.state.tableData} moveToDashBoard={this.moveToDashBoard.bind(this)} moveToCustomerDetails={this.moveToCustomerDetails.bind(this)} />
            case "debit":
                return <Debit onMoveToTransactions={this.onMoveToTransactions.bind(this)} moveToDashBoard={this.moveToDashBoard.bind(this)} moveToCustomerDetails={this.moveToCustomerDetails.bind(this)} />
            case "monthlyledger":
                return <MonthlyLedger onMoveToTransactions={this.onMoveToTransactions.bind(this)} moveToDashBoard={this.moveToDashBoard.bind(this)} moveToCustomerDetails={this.moveToCustomerDetails.bind(this)} />
            case "notifications":
                return <Notifications onMoveToTransactions={this.onMoveToTransactions.bind(this)} moveToDashBoard={this.moveToDashBoard.bind(this)} moveToCustomerDetails={this.moveToCustomerDetails.bind(this)} />
            case "profile":
                return <Profile onMoveToTransactions={this.onMoveToTransactions.bind(this)} moveToDashBoard={this.moveToDashBoard.bind(this)} />

            case "report":
                return <Report onMoveToTransactions={this.onMoveToTransactions.bind(this)} moveToDashBoard={this.moveToDashBoard.bind(this)} moveToCustomerDetails={this.moveToCustomerDetails.bind(this)} />
            case "activitylog":
                return <ActivityLog onMoveToTransactions={this.onMoveToTransactions.bind(this)} moveToDashBoard={this.moveToDashBoard.bind(this)} moveToCustomerDetails={this.moveToCustomerDetails.bind(this)} />
            case "bulkcustomercreation":
                return <BulkCustomerCreation
                    // backToStockOperation={this.backToStockOperation.bind(this)}
                    moveToCustomerDetails={this.moveToCustomerDetails.bind(this)}
                    onMoveToTransactions={this.onMoveToTransactions.bind(this)} moveToDashBoard={this.moveToDashBoard.bind(this)} />
            case "instock":
                return <StockOperation
                    moveToSTBCreation={this.moveToSTBCreation.bind(this)}
                    moveToSTBTransfer={this.moveToSTBTransfer.bind(this)}
                    moveToSTBReturns={this.moveToSTBReturns.bind(this)}
                    moveToCustomerTransfer={this.moveToCustomerTransfer.bind(this)}
                    moveToCreateCustomerWithSTB={this.moveToCreateCustomerWithSTB.bind(this)}
                    moveToBulkPackAddandRemove={this.moveToBulkPackAddandRemove.bind(this)}
                    moveToDashBoard={this.moveToDashBoard.bind(this)}
                    moveToCustomerDetails={this.moveToCustomerDetails.bind(this)}
                />
            case "stbcreation":
                return <STBCreation
                    backToStockOperation={this.backToStockOperation.bind(this)}
                    moveToDashBoard={this.moveToDashBoard.bind(this)}
                    moveToCustomerDetails={this.moveToCustomerDetails.bind(this)}
                />
            case "stbtransfer":
                return <STBTransfer
                    backToStockOperation={this.backToStockOperation.bind(this)}
                    moveToDashBoard={this.moveToDashBoard.bind(this)}
                    moveToCustomerDetails={this.moveToCustomerDetails.bind(this)}
                />

            case "stbreturns":
                return <STBReturns
                    backToStockOperation={this.backToStockOperation.bind(this)}
                    moveToDashBoard={this.moveToDashBoard.bind(this)}
                    moveToCustomerDetails={this.moveToCustomerDetails.bind(this)}
                />
            case "customertransfer":
                return <CustomerTransfer
                    backToStockOperation={this.backToStockOperation.bind(this)}
                    moveToDashBoard={this.moveToDashBoard.bind(this)}
                    moveToCustomerDetails={this.moveToCustomerDetails.bind(this)}
                />
            case "createcustomerwithstb":
                return <CreateCustomerWithSTB
                    backToStockOperation={this.backToStockOperation.bind(this)}
                    moveToDashBoard={this.moveToDashBoard.bind(this)}
                    moveToCustomerDetails={this.moveToCustomerDetails.bind(this)}
                />
            case "fulldataprocess":
                return <FullDataProcess
                    startAndEndDate={this.state.startAndEndDate}
                    resetTheUnpaid={this.resetTheUnpaid.bind(this)}
                    moveToDashBoard={this.moveToDashBoard.bind(this)}
                    moveToCustomerDetails={this.moveToCustomerDetails.bind(this)}
                    moveToCustomerDetails={this.moveToCustomerDetails.bind(this)}

                />
            case "bulkpackaddandremove":
                return <BulkPackAddandRemove
                    backToStockOperation={this.backToStockOperation.bind(this)}
                    moveToDashBoard={this.moveToDashBoard.bind(this)}
                    moveToCustomerDetails={this.moveToCustomerDetails.bind(this)}
                />
            case "allstb":
                return <AllStb
                    onMoveToTransactions={this.onMoveToTransactions.bind(this)}
                    moveToCustomerDetails={this.moveToCustomerDetails.bind(this)}
                    moveToCreateCustomerForm={this.moveToCreateCustomerForm.bind(this)}

                />
            default:
                return null;
        }
    }
    backToUnMappedSTB() {

        this.setState({
            content: "unmappedSTB",
            dashboardNavColor: "#ffffff",
            searchNavColor: "#ffffff",
            createCustomerNavColor: "#ffffff",
            settingsNavColor: "#ffffff",
            transactionsNavColor: GeneralValues.bottomNav,
            stockOperationColor: "#ffffff"

        });
    }
    moveToSTBCreation() {
        this.setState({ content: "stbcreation" });
    }
    moveToBulkPackAddandRemove() {
        this.setState({ content: "bulkpackaddandremove" });
    }
    // moveToSTBCreation1(){
    //     this.setState({content: "stbcreation"});
    // }
    moveToSTBTransfer() {
        this.setState({ content: "stbtransfer" })
    }
    moveToBulkCustomerCreation() {
        this.setState({ content: "bulkcustomercreation" })
    }

    moveToCreateCustomerWithSTB() {
        this.setState({ content: "createcustomerwithstb" })
    }
    moveToCustomerTransfer() {
        this.setState({ content: "customertransfer" })
    }
    moveToSTBReturns() {
        this.setState({ content: "stbreturns" })
    }
    moveToReport() {
        this.setState({ content: "report" });
    }
    moveToMonthlyLedger() {
        this.setState({ content: "monthlyledger" });
    }
    moveToDashBoard() {
        this.setState({
            content: "dashboard",
            dashboardNavColor: GeneralValues.bottomNav,
            searchNavColor: "#ffffff",
            createCustomerNavColor: "#ffffff",
            settingsNavColor: "#ffffff",
            transactionsNavColor: "#ffffff",
            stockOperationColor: "#ffffff"
        })
    }
    resetTheUnpaid() {
        this.setState({ startAndEndDate: {} })
    }
    moveToUnPaid(typeOfDetails) {
        var formate = {};
        if (typeOfDetails == "today") {
            formate.start = new Date();
            formate.end = new Date();
        } else if (typeOfDetails == "tomorrow") {
            var n1 = new Date();
            var tomorrow = new Date(n1);
            tomorrow.setDate(tomorrow.getDate() + 1);
            formate.start = new Date(tomorrow);
            formate.end = new Date(tomorrow);

        } else if (typeOfDetails == "dayAfterTomorrow") {
            var n2 = new Date();
            var tomorrow1 = new Date(n2);
            tomorrow1.setDate(tomorrow1.getDate() + 2);
            formate.start = n2;
            formate.end = tomorrow1;
        } else {
            var date = new Date(), y = date.getFullYear(), m = date.getMonth();
            var lastDay = new Date(y, m + 1, 0);
            formate.start = new Date();
            formate.end = lastDay;
        }
        debugger;
        this.setState({
            content: "fulldataprocess",
            dashboardNavColor: "#ffffff",
            searchNavColor: "#ffffff",
            createCustomerNavColor: GeneralValues.bottomNav,
            settingsNavColor: "#ffffff",
            transactionsNavColor: "#ffffff",
            stockOperationColor: "#ffffff",
            startAndEndDate: formate
        })
    }

    backToStockOperation() {
        this.setState({ content: "instock" })
    }
    moveToUnmappedSTB() {
        this.setState({
            content: "unmappedSTB",
            dashboardNavColor: "#ffffff",
            searchNavColor: "#ffffff",
            createCustomerNavColor: "#ffffff",
            settingsNavColor: "#ffffff",
            transactionsNavColor: GeneralValues.bottomNav,
            stockOperationColor: "#ffffff"
        });

    }



    moveToSearchWithQuery(text) {
        this.setState({
            content: "search",
            searchQuery: text,
            dashboardNavColor: "#ffffff",
            searchNavColor: GeneralValues.bottomNav,
            createCustomerNavColor: "#ffffff",
            settingsNavColor: "#ffffff",
            stockOperationColor: "#ffffff"
        });
        setTimeout(() => {
            this.setState({ searchQuery: "" });
            console.log("current txt", text);
        }, 1000)
        console.log("current txt", text);
    }


    onMoveToTransactions() {
        this.setState({ content: "transactions" });
    }
    moveToAllSTB() {
        this.setState({
            content: "allstb",
            dashboardNavColor: "#ffffff",
            searchNavColor: "#ffffff",
            settingsNavColor: "#ffffff",
            transactionsNavColor: GeneralValues.bottomNav,
            stockOperationColor: "#ffffff",


        })
    }
    moveToCreateCustomerForm(text) {
        debugger;
        console.log("unmappedstbno", text);
        this.setState({
            content: "createCustomerForm",
            specifyUnmappedSTB: text,
            dashboardNavColor: "#ffffff",
            searchNavColor: "#ffffff",
            createCustomerNavColor: GeneralValues.bottomNav,
            settingsNavColor: "#ffffff",
            transactionsNavColor: "#ffffff",
            stockOperationColor: "#ffffff"

        });
        console.log("data", this.state.specifyUnmappedSTB);
        debugger;
    }

    onClickCredit() {


        this.setState({ content: "credit" });

    }
    onClickDebit() {
        this.setState({ content: "debit" });
    }
    onClickNotifications() {
        this.setState({
            content: "notifications",

            dashboardNavColor: "#ffffff",
            searchNavColor: "#ffffff",
            createCustomerNavColor: "#ffffff",
            settingsNavColor: "#ffffff",
            transactionsNavColor: GeneralValues.bottomNav
        });
    }

    onClickProfile() {
        this.setState({
            content: "profile",
            dashboardNavColor: "#ffffff",
            searchNavColor: "#ffffff",
            createCustomerNavColor: "#ffffff",
            settingsNavColor: "#ffffff",
            transactionsNavColor: GeneralValues.bottomNav

        });
    }
    onClickActivityLog() {
        this.setState({ content: "activitylog" });
    }
    moveToAddPackPage(customerNo, STBNo) {
        this.setState({ content: "addpackpage", customerNo: customerNo, unmappedSTBNO: STBNo });
    }
    async moveToBackToCustomerSearch() {

        this.setState({ content: "search", searchQuery: this.state.searchText });
        console.log("backtosearch : ", this.state.searchQuery)

    }
    async moveToBackToUnmappedSTB() {

        this.setState({ content: "unmappedSTB" });
    }

    moveToCustomerDetails(item, text, searchTxt) {
        console.log("item", text);
        console.log("text", item);
        console.log("working super", searchTxt);
        const data1 = item;

        console.log("data1", text, searchTxt);
        this.state.searchText = searchTxt;
        debugger;
        this.setState({
            content: "customerdetails",
            specifyUserDetails: text,
            searchQuery: searchTxt,
            dashboardNavColor: "#ffffff",
            searchNavColor: GeneralValues.bottomNav,
            createCustomerNavColor: "#ffffff",
            settingsNavColor: "#ffffff",
            transactionsNavColor: "#ffffff",
            stockOperationColor: "#ffffff"
        });
        console.log("data", this.state.specifyUserDetails);
    }
    showBtn() {
        if (this.state.isShowCancelBtn) {
            return (
                <MdCancel
                    color={"#ffffff"}
                    size={40}
                    onClick={() => {
                        this.setState({ isShowAdvertisementDialog: false })
                    }}
                />
            );

        } else {
            return (
                <label style={{ color: "#ffffff" }}> Skip ads in<span style={{ fontWeight: "bold" }}> {this.state.advtDuration} </span> sec</label>
            );
        }
    }


    isShowStockOperation() {

        if (this.state.isShowStock != "false") {

            return (
                <NavStockOperation
                    onClick={() => {
                        this.setState({
                            content: "instock",
                            dashboardNavColor: "#ffffff",
                            searchNavColor: "#ffffff",
                            createCustomerNavColor: "#ffffff",
                            settingsNavColor: "#ffffff",
                            transactionsNavColor: "#ffffff",
                            stockOperationColor: GeneralValues.bottomNav
                        });
                    }
                    }
                    color={this.state.stockOperationColor}
                >
                    <div className="bottom-nav-active">
                        <MdShoppingBasket className="font-size" />
                        <NavLabel>Stock Operation</NavLabel>
                    </div>
                </NavStockOperation>
            );
        } else {
            return null;
        }
    }
    render() {
        return (
            // <MainContainer>
            //     <LoadContent></LoadContent>
            //     <NavDiv>

            //         <NavHome>
            //             <MdHome className="font-size" />
            //             <NavLabel>DASHBOARD</NavLabel>
            //         </NavHome>
            //         <NavSearch >
            //             <MdSearch className="font-size" />
            //             <NavLabel>SEARCH</NavLabel>
            //         </NavSearch>
            //         <NavCustomer>
            //             <MdPermIdentity className="font-size" />
            //             <NavLabel>Customer Details</NavLabel>
            //         </NavCustomer>
            //         <NavSettings>
            //             <MdSettings className="font-size" />
            //             <NavLabel>Settings</NavLabel>
            //         </NavSettings>
            //     </NavDiv>
            // </MainContainer>

            <div style={{ display: "flex", height: "100vh", width: "100%" }}>
                <div style={{ flex: 1 }}>

                    <div


                        onScroll={() => console.log()}

                        style={{ width: "100%", height: "90%", overflow: "auto" }}
                    >
                        {this.showTheContent(this.state.content)}
                    </div>
                    <div
                        style={{ width: "100%", height: "10%", borderWidth: 0.5, borderColor: "#ada092", boxShadow: "0px 0px 0px 1px #ada092" }}
                    >
                        <NavDiv style={{ borderWidth: 0.5, borderColor: "#ada092", boxShadow: "0px 0px 0px 1px #ada092" }}>

                            <NavHome

                                onClick={() => {
                                    this.setState({
                                        content: "dashboard",
                                        dashboardNavColor: GeneralValues.bottomNav,
                                        searchNavColor: "#ffffff",
                                        createCustomerNavColor: "#ffffff",
                                        settingsNavColor: "#ffffff",
                                        transactionsNavColor: "#ffffff",
                                        stockOperationColor: "#ffffff"
                                    })
                                }
                                }
                                color={this.state.dashboardNavColor}
                            >
                                <div className="bottom-nav-active">
                                    <MdHome className="font-size" />
                                    <NavLabel>DASHBOARD</NavLabel>
                                </div>
                            </NavHome>
                            <NavSearch
                                onClick={() => {
                                    this.setState({
                                        content: "search",
                                        dashboardNavColor: "#ffffff",
                                        searchNavColor: GeneralValues.bottomNav,
                                        createCustomerNavColor: "#ffffff",
                                        settingsNavColor: "#ffffff",
                                        transactionsNavColor: "#ffffff",
                                        stockOperationColor: "#ffffff"
                                    })
                                }
                                }
                                color={this.state.searchNavColor}
                            >
                                <div className="bottom-nav-active">
                                    <MdSearch className="font-size" />
                                    <NavLabel >SEARCH</NavLabel>
                                </div>
                            </NavSearch>
                            <NavCustomer
                                onClick={() => {
                                    this.setState({
                                        content: "fulldataprocess",
                                        dashboardNavColor: "#ffffff",
                                        searchNavColor: "#ffffff",
                                        createCustomerNavColor: GeneralValues.bottomNav,
                                        settingsNavColor: "#ffffff",
                                        transactionsNavColor: "#ffffff",
                                        stockOperationColor: "#ffffff"
                                    })
                                }
                                }
                                color={this.state.createCustomerNavColor}
                            >
                                <div className="bottom-nav-active">
                                    {/* <MdPersonAdd className="font-size" /> */}
                                    <IoIosCloseCircle className="font-size" />
                                    <NavLabel>Unpaid</NavLabel>
                                </div>
                            </NavCustomer>
                            <NavBulkOperations
                                onClick={() => {
                                    this.setState({
                                        content: "bulkoperations",
                                        dashboardNavColor: "#ffffff",
                                        searchNavColor: "#ffffff",
                                        createCustomerNavColor: "#ffffff",
                                        settingsNavColor: GeneralValues.bottomNav,
                                        transactionsNavColor: "#ffffff",
                                        stockOperationColor: "#ffffff"
                                    })
                                }
                                }
                                color={this.state.settingsNavColor}
                            >
                                <div className="bottom-nav-active">
                                    <MdSettings className="font-size" />
                                    <NavLabel>BULK OPERATIONS</NavLabel>
                                </div>
                            </NavBulkOperations>
                            {this.isShowStockOperation()}
                            <NavTransactions
                                onClick={() => {
                                    this.setState({
                                        content: "transactions",
                                        dashboardNavColor: "#ffffff",
                                        searchNavColor: "#ffffff",
                                        createCustomerNavColor: "#ffffff",
                                        settingsNavColor: "#ffffff",
                                        transactionsNavColor: GeneralValues.bottomNav,
                                        stockOperationColor: "#ffffff"
                                    })
                                }
                                }
                                color={this.state.transactionsNavColor}
                            >
                                <div className="bottom-nav-active">
                                    <MdTransferWithinAStation className="font-size" />
                                    <NavLabel>Others</NavLabel>
                                </div>
                            </NavTransactions>


                            {/* <NavUnpaidSTB
                                onClick={() => {
                                    this.setState({
                                        content: "UnpaidSTB",
                                        dashboardNavColor: "#ffffff",
                                        searchNavColor: "#ffffff",
                                        createCustomerNavColor: "#ffffff",
                                        settingsNavColor: GeneralValues.bottomNav,
                                        transactionsNavColor: "#ffffff",
                                        stockOperationColor: "#ffffff"
                                    })
                                }
                                }
                                color={this.state.settingsNavColor}
                            >
                                <div className="bottom-nav-active"> 
                                <MdSettings className="font-size" />
                                <NavLabel>UnpaidSTB</NavLabel>
                                </div>
                            </NavUnpaidSTB> */}

                        </NavDiv>

                    </div>

                </div>

                {this.state.isShowNotification
                    ?
                    <div style={{ position: "absolute", right: 15, bottom: 90, maxHeight: "50vh", overflow: "auto" }}>
                        <label onClick={() => {
                            this.setState({ isShowNotification: false, notificationDataList: [] })
                        }} style={{ color: "red", textAlign: "center", alignSelf: "center", marginLeft: 100 }}>Close</label>
                        {this.state.notificationDataList.map((item, i) => {
                            return (
                                <Card
                                    onClick={() => {
                                        this.setState({ isShowNotification: false }, () => {
                                            this.onClickNotifications()
                                        })
                                    }}
                                    style={{ borderRadius: 10, width: 250, height: 100, borderWidth: 0.01, borderColor: GeneralValues.mustard, marginBottom: 10, overflow: "hidden", padding: 0 }}>
                                    <Card.Body className="pl-3 ">
                                        <label style={{ fontSize: 8, color: GeneralValues.garland, display: "block", marginTop: -10 }}> {item.created}</label>
                                        <label style={{ fontSize: 11, color: GeneralValues.garland, display: "block", height: 12, overflow: "hidden" }}>{item.error}</label>
                                        <label style={{ fontSize: 11, color: GeneralValues.garland, display: "block" }}><span style={{ fontSize: 10, color: GeneralValues.blueColor }}>User : </span>{item.user}</label>
                                        <label style={{ fontSize: 11, color: GeneralValues.garland, display: "block" }}><span style={{ fontSize: 10, color: GeneralValues.blueColor }}>Action : </span>{item.values}</label>






                                    </Card.Body>
                                </Card>
                            );
                        })}

                    </div>
                    : null
                }
                <Drawer

                    open={this.state.isShowAdvertisementDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card

                        style={{ backgroundColor: "transparent", borderColor: "transparent", border: "none" }}>


                        <Card.Body className="pl-3 ">



                            <div style={{ width: 300, height: 500, marginBottom: 80 }}>
                                <img

                                    style={{ width: 300, height: 500, backgroundSize: "cover", marginBottom: 83 }}
                                    src={this.state.advtURL} className="spinnerContent" color="green" />
                            </div>
                            <div style={{ display: "block", textAlign: "center", width: "100%", marginTop: 20 }}>

                                {this.showBtn()}

                            </div>

                        </Card.Body>
                    </Card>

                </Drawer>



            </div>

        )
    }
}