export const CAS = {
    NSTV_drm: [
        {
            sno: 1,
            model: "UV MAX",
            type: "HD",
            cas: "NSTV_drm"
        },
        {
            sno: 2,
            model: "ST 9",
            type: "HD",
            cas: "NSTV_drm"
        },
        {
            sno: 3,
            model: "V1 PRO",
            type: "HD",
            cas: "NSTV_drm"
        }

    ],
    Phandos_OTT: [
        {
            sno: 3,
            model: "OTT",
            type: "OTT",
            cas: "Phandos_OTT"
        }
    ],
    H8_ISP: [
        {
            sno: 3,
            model: "ISP",
            type: "ISP",
            cas: "H8_ISP"
        }
    ]
}