import React from 'react';
import { Button, ButtonGroup, Container, ButtonToolbar, Jumbotron, Card } from 'react-bootstrap';
import {
    Rootcontainer, SearchDiv, MainContainer, RootDiv, RootTitle, RootBal, RootRight, Amt, MoreDetails,
    RootLeft, RootValue, SelectedDiv, SelectedPackage
} from './styled';

import './index.css';
import { Redirect } from 'react-router-dom';
import CSVReader from "react-csv-reader";
import DialogBoxs from './DialogBoxs';
import { CSVLink, CSVDownload } from "react-csv";
import Checkboxs from 'muicss/lib/react/checkbox';
import {
    MdPermIdentity,
    MdHome,
    MdFiberPin,
    MdCreditCard,
    MdRefresh,
    MdAddCircle,
    MdRemoveCircle,
    MdPowerSettingsNew,
    MdArrowBack,
    MdSearch, MdClose,



} from 'react-icons/md';
import loadinggif from '../../assets/loding-gif.gif';
import Drawer from 'react-drag-drawer';
import API from '../../Api';
import logo from '../../assets/logo-3.png';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import Loader from 'react-loader-spinner';
import Undraw from 'react-undraw';
import GeneralValues from '../../GobelElements/Values';
import { FaPlus, FaCircle } from "react-icons/fa";
export default class index extends React.Component {
    constructor(props) {
        super(props);
        console.log("data from search", this.props.specifyUserDetails);
        const data = this.props.specifyUserDetails;
        console.log("data in customer details", data);

        this.state = {
            ll: [],
            userMetaData: data,
            userSTBDetails: {},
            selectedSTB: [],
            isShowAddPackageDialogBox: false,
            isShowRemovePackageDialogBox: false,
            defaultValue: "",
            spinnerVisible: false,
            pageCount: 3,
            totalPage: 0,
            count: '',
            lcoID: localStorage.getItem("userID"),
            lcoPassword: localStorage.getItem("userPassword"),
            domainName: localStorage.getItem("domain"),
            pageNo: 3,
            renderTheSearchResult: [],
            searchText: props.searchQuery ? props.searchQuery : "",
            firstLoad: "",
            animating: false,
            customerList: [],
            totalAmount: 0,
            selected: [],
            totalCount: 0,
            pageCount: 1,
            tableDataValue: '',
            isVisibleConformADD_Dialog: false,
            isVisibleConformRemove_Dialog: false,
            isVisibleTransactions_Dialog: false,
            isVisibleRemove_Dialog: false,
            valuePack: 0,
            removeValuePack: 0,
            isShowSuccessDialog: false,
            creditRow: [],
            defauleValue: "",
            totalCount: 1,
            isChecked: false,
            fullDataList: [],
            isSuccess: 0,
            isError: 0,
            isEmpty: false,
            isCSVOperation: false,
            payAllCheckMessage: "",
            isShowWarningPayCheck: false,
            isShowSuccessPayCheck: false,
            isShowPayAllSuccessDialog: false,
            isErrorInPayProcess: "",
            CSVDataArray: [],
            STBListData: [],
            totalCSVAmount: 0,
            numberOfRowsInCsv: 0,
            CompletedCustomerNum: 0,
            isBulkCSVOperationRunning: 0,
            noOfCustomerCSVSelect: "",
            selectedCSVCount: "",
            failedCSVData: [],
            isShowFailedCSVDialog: false,
            selectedItemsCount: 0,
            csvAmount: 0,
            isLcoBalanceDialog: false,
            isShowWarningForInversePay: false,
            inversePayTopayAmount: 0,
            isShowLowBalanceInInversePay: false,
            isShowCSVFileUploadDialog: false,
            noOfCSVFileUploaded: 0,
            isShowPayAllLowBalanceDialog: false,
            payAllRequireAmount: 0,
            isShowLoader: false,
            isShowLogOutDialog: false,
            ProcessDataList: [],
            isShowRefreshWarningDialog: false,
            refreshDataToProcess: [],
            numberOfRowInRefreshProcess: 0,
            isShowWarningForPayNow: false,
            isShowWarningForActivate: false,
            isShowWarningForDeactivate: false,
            STBSearchFailedData: [{ "error": "data" }],
            isShowUnprocessedData: false,
            un: [],
            isShowPackAddOrRemoveDialog: false,
            whichType: "",
            whichCAS: "",
            casList: [],
            isShowCASListDialog: false,
            isShowPackDialog: false,
            availablePack: [],
            availablePackSearchResult: [],
            requireAmountForPackages: 0,
            EmptyErrorInPackageDialog: "",
            selectedPackID: [],
            isShowAddPackWarning: false,
            isShowProcessDialog: false,
            noOfDataGoingToProcess: 0,
            noOfcompletedData: 0,
            unProcessedData: [],
            dataGoingToProcess: [],
            isShowUnProcessedDataListDialog: false,
            isShowRemovePackWarning: false,
            isShowMantainanceDialog: false,
            maintIMG: "",
            maintText: "",
            selectedPackageData: [],
            isShowPackageDialog: false,
            isShowStockLoader: false,
            inStockListSearchText: "",
            inStockDataList: []


        }

        this.getLCO_Details();
        //  this.getdata1()
        console.log("try", this.props.tableData);
        window.addEventListener('scroll', this.handleScroll);

    }
    apiFetchForSTBDetails(i, STBNo) {
        if (this.state.numberOfRowsInCsv) {
            console.log("csv data", i, STBNo);
            const { lcoID, lcoPassword, domainName } = this.state;
            API.stbDetails(lcoID, lcoPassword, "stbdetail", STBNo, domainName)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("reposne", responseJson);
                    if (!responseJson.error) {

                        console.log("data CSV STB", responseJson);
                        var data = responseJson;
                        data.toggleMode = true;
                        var oldData = this.state.STBListData;
                        oldData.push(data);
                        var tot = this.state.csvAmount;
                        tot = parseFloat(data.topay) + parseFloat(tot);
                        this.setState({ STBListData: oldData, csvAmount: tot, noOfCSVFileUploaded: this.state.noOfCSVFileUploaded + 1 });
                        if ((this.state.numberOfRowsInCsv - 1) > i) {
                            debugger;
                            this.apiFetchForSTBDetails(i + 1, this.state.CSVDataArray[i + 1].toString());
                        } else {
                            this.setState({ isSpinnerVisible: false });
                        }
                    } else {
                        // console.log("Error in STb Search",error);
                        this.setState({ noOfCSVFileUploaded: this.state.noOfCSVFileUploaded + 1 });

                        var errordata = [];
                        errordata = this.state.un;
                        errordata.push({ "error": responseJson.error, "sno": STBNo });
                        this.setState({ un: errordata });
                        console.log("error", errordata);
                        if ((this.state.numberOfRowsInCsv - 1) > i) {
                            this.apiFetchForSTBDetails(i + 1, this.state.CSVDataArray[i + 1].toString());
                        } else {
                            this.setState({ isSpinnerVisible: false });
                        }
                    }

                }).catch((error) => {

                    if ((this.state.numberOfRowsInCsv - 1) > i) {
                        this.apiFetchForSTBDetails(i + 1, this.state.CSVDataArray[i + 1].toString());
                    } else {
                        this.setState({ isSpinnerVisible: false });
                    }
                });
        }


    }
    handleCSVFileUpload = e => {
        var arrayOfData = [];
        console.log("csv", e);

        for (var i = 1; i < e.length; i++) {
            if (e[i][0]) {
                arrayOfData.push(e[i]);
            }
        }
        this.setState({ CSVDataArray: arrayOfData, STBListData: [] });
        var len = (this.state.CSVDataArray.length);

        this.setState({ numberOfRowsInCsv: arrayOfData.length });
        console.log("length", len, arrayOfData);
        debugger;
        this.setState({ isShowCSVFileUploadDialog: true });
        this.apiFetchForSTBDetails(0, this.state.CSVDataArray[0].toString());

        console.log("Error data", this.state.STBSearchFailedData);

        var total = 0;
        for (var a of this.state.STBListData) {
            total = parseFloat(total) + parseFloat(a.topay);
        }
        this.setState({ totalAmount: total });
        // setTimeout(() => {

        //     let arr = Object.keys(arrayOfData).reduce((prevVal, key) => {
        //         let o = { ...arrayOfData[key],toggleMode:true };
        //         prevVal.push(o);
        //         return prevVal;
        //     }, []);
        //     this.setState({ STBListData: arr ,isSpinnerVisible:false});
        //     console.log("data",this.state.STBListData);
        //     var total = 0;
        //     for(var a of this.state.STBListData){
        //         total = parseFloat(total)+parseFloat(a.topay);
        //     }
        //     this.setState({totalAmount:total});
        // }, 1000)
        // this.setState({isSpinnerVisible:false});
    }

    hindBulkCSVOperationRunning() {
        this.getdata1();
        this.getLCO_Details();
        this.state.selectedSTB = [];
        this.setState({ isBulkCSVOperationRunning: false, selectedSTB: [], noOfCustomerCSVSelect: "", noOfCustomerCSVSelect: 0, CompletedCustomerNum: 0, STBListData: [], isCSVOperation: false, csvAmount: 0, totalAmount: 0, selectedItemsCount: 0, selectedSTB: [] });
        if (this.state.failedCSVData.length) {
            this.setState({ isShowFailedCSVDialog: true });
        }

    }
    hindfailedCSVDialog() {
        this.setState({ isBulkCSVOperationRunning: false, selectedSTB: [], noOfCustomerCSVSelect: "", isShowFailedCSVDialog: false, failedCSVData: [] });
    }
    hindRefreshWarningDialog() {
        this.setState({ isShowRefreshWarningDialog: false });
    }
    hindPayAllCheckSuccessDialog() {
        this.setState({ isShowSuccessPayCheck: false })
    }
    hindPayAllSuccessDialog() {
        this.getdata1();
        this.getLCO_Details();
        this.setState({ isShowPayAllSuccessDialog: false })
    }
    hindPayAllWarningDialog() {

        this.setState({ isShowWarningPayCheck: false })
    }
    hindLowBalanceDialog() {

        this.setState({ isLcoBalanceDialog: false })
    }
    hindWarningInversePayDialog() {

        this.setState({ isShowWarningForInversePay: false })
    }
    hindShowSuccessInversePayReplay() {

        this.setState({ isShowSuccessInversePayReplay: false })
    }

    hindLowBalanceInInversePay() {

        this.setState({ isShowLowBalanceInInversePay: false })
    }
    hindCSVFileUploadDialog() {

        this.setState({ isShowCSVFileUploadDialog: false, noOfCSVFileUploaded: 0 })
        if (this.state.un.length) {
            this.setState({ isShowUnprocessedData: true });
        }
    }

    hindPayAllLowBalanceDialog() {

        this.setState({ isShowPayAllLowBalanceDialog: false });
    }
    hindPackageDetailDialog() {
        this.setState({ isShowPackageDialog: false });
    }

    hindWarningForPayNow() {
        this.setState({ isShowWarningForPayNow: false })
    }
    hindWarningForActivate() {
        this.setState({ isShowWarningForActivate: false })
    }
    hindWarningForDeactivate() {
        this.setState({ isShowWarningForDeactivate: false })
    }
    hindUnprocessedData() {
        this.setState({ isShowUnprocessedData: false, un: [] });
    }
    hindPackAddOrRemoveDialog() {
        this.setState({ isShowPackAddOrRemoveDialog: false })
    }
    hindCASListDialog() {
        this.setState({ isShowCASListDialog: false });
    }
    hindPackDialog() {
        this.setState({ isShowPackDialog: false, requireAmountForPackages: 0, availablePack: [], availablePackSearchResult: [] });
    }
    hindAddPackWarning() {
        this.setState({ isShowAddPackWarning: false })
    }


    hindProcessDialog() {
        this.setState({ isShowProcessDialog: false, selected: [], noOfcompletedData: 0, noOfDataGoingToProcess: 0, selectedSTB: [], noOfCustomerCSVSelect: "", CompletedCustomerNum: 0, STBListData: [], isCSVOperation: false, csvAmount: 0, totalAmount: 0, selectedItemsCount: 0, isBulkCSVOperationRunning: false, selectedSTB: [], noOfCustomerCSVSelect: "", noOfCustomerCSVSelect: 0, CompletedCustomerNum: 0, STBListData: [], isCSVOperation: false, csvAmount: 0, totalAmount: 0, selectedItemsCount: 0, selectedSTB: [] });
        var len = this.state.unProcessedData.length;
        if (len) {
            this.setState({ isShowUnProcessedDataListDialog: true });
        } else {
            this.getLCO_Details();
            this.getdata1();
        }

    }
    hindUnProcessedDataListDialog() {
        this.setState({ isShowUnProcessedDataListDialog: false, unProcessedData: [] });
        this.getLCO_Details();
        this.getdata1();
    }
    hindRemovePackWarning() {
        this.setState({ isShowRemovePackWarning: false });
    }


    csvData = [
        [
            "STB",

        ],

    ];
    nextPageData(data, pageNo) {
        let lengthOfACustomerList = this.state.customerList.length;
        console.log("lenght of the list", this.state.totalCount);
        if (lengthOfACustomerList < this.state.totalCount) {
            // console.log("pageCount", pageNo);
            // this.setState({ animating: true });
            this.setState({ isShowLoader: true });
            const { lcoID, lcoPassword, domainName, searchText } = this.state;
            API.searchAPI(lcoID, lcoPassword, "search", searchText, pageNo, domainName)
                .then((response) => response.json())
                .then((responsejson) => {
                    if (responsejson.count) {
                        console.log("SearchResponse", responsejson);
                        let oldData = data;
                        let newData = oldData.concat(responsejson.content);
                        let count = this.state.pageCount + 1;
                        let arrayPackageDetails = Object.keys(newData).reduce((prevVal, key) => {
                            let o = { ...newData[key] };
                            prevVal.push(o);
                            return prevVal;
                        }, []);

                        let oldDataList;
                        if (this.state.selectedSTB.length) {
                            for (let i = 0; i < this.state.selectedSTB.length; i++) {
                                for (let j = 0; j < arrayPackageDetails.length; j++) {
                                    if (arrayPackageDetails[j].Customernum == this.state.selectedSTB[i].customerNo) {
                                        oldDataList = arrayPackageDetails;
                                        let oldToggleMode = oldDataList[j].toggleMode;
                                        oldDataList[j].toggleMode = true;
                                        this.setState({ arrayPackageDetails: oldDataList });
                                    }
                                }


                            }
                        }
                        this.setState({ animating: false, isShowSearchList: true, customerList: arrayPackageDetails, pageCount: count, fullDataList: arrayPackageDetails, isShowLoader: false });
                        // console.log("data", this.state.customerList);
                        // if (newData.length < responsejson.count) {

                        //     this.nextPageData(this.state.customerList, (this.state.pageCount + 1));
                        // }
                    }
                })
                .catch((err) => {

                    console.log("error", err);
                    this.setState({ isSpinnerVisible: false });
                    // break


                });
        }
    }
    // getdataPage3(data) {
    //     const { lcoID, lcoPassword, domainName, searchText } = this.state;
    //     API.searchAPI(lcoID, lcoPassword, "search", searchText, 3, domainName)
    //         .then((response) => response.json())
    //         .then((responsejson) => {
    //             // console.log("SearchResponse", responsejson);
    //             let oldData = data;
    //             let newData = oldData.concat(responsejson.content);
    //             // if (newData.length < responsejson.count) {

    //             // this.nextPageData(this.state.customerList, (this.state.pageCount + 1));
    //             let arrayPackageDetails = Object.keys(newData).reduce((prevVal, key) => {
    //                 let o = { ...newData[key] };
    //                 prevVal.push(o);
    //                 return prevVal;
    //             }, []);
    //             let oldDataList;
    //             if (this.state.selectedSTB.length) {
    //                 for (let i = 0; i < this.state.selectedSTB.length; i++) {
    //                     for (let j = 0; j < arrayPackageDetails.length; j++) {
    //                         if (arrayPackageDetails[j].Customernum == this.state.selectedSTB[i].customerNo) {
    //                             oldDataList = arrayPackageDetails;
    //                             let oldToggleMode = oldDataList[j].toggleMode;
    //                             oldDataList[j].toggleMode = true;
    //                             this.setState({ arrayPackageDetails: oldDataList });
    //                         }
    //                     }


    //                 }
    //             }

    //             this.setState({ isSpinnerVisible: false, isShowSearchList: true, customerList: arrayPackageDetails, fullDataList: arrayPackageDetails });
    //             // } else {
    //             //     this.setState({ isSpinnerVisible: false, isShowSearchList: true, customerList: newData });
    //             // }




    //         })
    //         .catch((err) => {

    //             console.log("error", err);
    //             this.setState({ isSpinnerVisible: false });
    //             // break


    //         });

    // }
    // getdataPage2(data) {

    //     const { lcoID, lcoPassword, domainName, searchText } = this.state;
    //     API.searchAPI(lcoID, lcoPassword, "search", searchText, 2, domainName)

    //         .then((response) => response.json())
    //         .then((responsejson) => {
    //             // console.log("SearchResponse", responsejson);
    //             let oldData = data;
    //             let newData = oldData.concat(responsejson.content);
    //             if (newData.length < responsejson.count) {

    //                 this.getdataPage3(newData);
    //             } else {
    //                 let arrayPackageDetails = Object.keys(newData).reduce((prevVal, key) => {
    //                     let o = { ...newData[key] };
    //                     prevVal.push(o);
    //                     return prevVal;
    //                 }, []);
    //                 let oldDataList;
    //                 if (this.state.selectedSTB.length) {
    //                     for (let i = 0; i < this.state.selectedSTB.length; i++) {
    //                         for (let j = 0; j < arrayPackageDetails.length; j++) {
    //                             if (arrayPackageDetails[j].Customernum == this.state.selectedSTB[i].customerNo) {
    //                                 oldDataList = arrayPackageDetails;
    //                                 let oldToggleMode = oldDataList[j].toggleMode;
    //                                 oldDataList[j].toggleMode = true;
    //                                 this.setState({ arrayPackageDetails: oldDataList });
    //                             }
    //                         }


    //                     }
    //                 }
    //                 this.setState({ isSpinnerVisible: false, isShowSearchList: true, customerList: arrayPackageDetails, fullDataList: arrayPackageDetails });
    //             }
    //         })
    //         .catch((err) => {

    //             console.log("error", err);
    //             this.setState({ isSpinnerVisible: false });
    //             // break


    //         });

    // }
    getdata1() {

        this.setState({ isSpinnerVisible: true });
        let data = [];
        // if(this.state.initialPageCount <=this.state.totalPageCount){


        this.setState({ isSpinnerVisible: true });



        const { lcoID, lcoPassword, domainName, searchText } = this.state;
        if (this.state.firstLoad == "") {
            this.state.searchText = "mappedcustomer";
            this.setState({ defauleValue: "mappedcustomer" })
        }
        console.log("currentactivate", this.state.searchText)
        API.searchAPI(lcoID, lcoPassword, "search", this.state.searchText, 1, domainName)

            .then((response) => response.json())
            .then((responsejson) => {
                console.log("SearchResponse", responsejson);
                if (responsejson.message != "no data") {
                    let page1Data = [];
                    page1Data = responsejson.content;
                    this.setState({ isSpinnerVisible: true });
                    console.log("count", page1Data.length);
                    console.log("bulk data :", responsejson.count)
                    this.setState({ totalCount: responsejson.count });
                    // if (page1Data.length < responsejson.count) {


                    // this.getdataPage2(page1Data);
                    // } else {
                    let arrayPackageDetails = Object.keys(responsejson.content).reduce((prevVal, key) => {
                        let o = { ...responsejson.content[key] };
                        prevVal.push(o);

                        return prevVal;
                    }, []);
                    let oldDataList;
                    if (this.state.selectedSTB.length) {
                        debugger;
                        for (let i = 0; i < this.state.selectedSTB.length; i++) {
                            for (let j = 0; j < arrayPackageDetails.length; j++) {
                                if (arrayPackageDetails[j].Customernum == this.state.selectedSTB[i].customerNo) {
                                    oldDataList = arrayPackageDetails;
                                    let oldToggleMode = oldDataList[j].toggleMode;
                                    oldDataList[j].toggleMode = true;
                                    this.setState({ arrayPackageDetails: oldDataList });
                                }
                            }


                        }
                        // }
                        // this.setState({ isSpinnerVisible: false, isShowSearchList: true, customerList: arrayPackageDetails, fullDataList: arrayPackageDetails });
                    }
                    this.setState({ isSpinnerVisible: false, isShowSearchList: true, customerList: arrayPackageDetails, fullDataList: arrayPackageDetails });

                    //  this.setState({ isSpinnerVisible: false, isShowSearchList: true,customerList:responsejson.content });


                } else {
                    this.setState({ isSpinnerVisible: false, isShowSearchList: false, customerList: [] });
                    // break
                }
            })
            .catch((err) => {

                console.log("error", err);
                this.setState({ isSpinnerVisible: false });
                // break


            });



    }
    toggleChangeInCustomerAdd(index) {

        let oldDataList = this.state.customerList;
        let oldToggleMode = oldDataList[index].toggleMode;
        oldDataList[index].toggleMode = !oldToggleMode;
        this.setState({ customerList: oldDataList });

        let { selected } = this.state;
        debugger;
        let oldValue;
        oldValue = (parseFloat(oldDataList[index].topay).toFixed(2));
        if (oldDataList[index].toggleMode) {



            selected.push({ name: oldDataList[index].name, amount: oldDataList[index].topay, customerNo: oldDataList[index].Customernum, stbs: oldDataList[index].stbs })
            console.log("Selected STB", this.state.selectedSTB);
            this.setState({ selectedSTB: selected, selectedItemsCount: selected.length });


            var data = (parseFloat(this.state.totalAmount) + parseFloat(oldValue)).toFixed(2);
            this.setState({ totalAmount: data });

            console.log("total amt + :", (this.state.totalAmount));
        }
        else {

            for (var i = 0; i < this.state.selectedSTB.length; i++) {
                if (this.state.selected[i].customerNo == oldDataList[index].Customernum) {
                    this.state.selected.splice(i, 1);
                    break;
                }
                this.setState({ selectedSTB: selected })


            }
            var data1 = (parseFloat(this.state.totalAmount) - parseFloat(oldValue)).toFixed(2);
            this.setState({ totalAmount: data1, selectedItemsCount: selected.length });
        }

        // this.setState({selectedItemsCount : this.state.selectedSTB.length});

    }
    ActivateOrPayASingleCustomer(i, SingleSTBData) {
        this.setState({ isSpinnerVisible: false, isBulkCSVOperationRunning: true });
        if (SingleSTBData) {
            if (SingleSTBData.toggleMode) {

                const { lcoID, lcoPassword } = this.state;
                console.log("data in recurse function", i, SingleSTBData.Customernum);
                API.payNowAPI(lcoID, lcoPassword, SingleSTBData.Customernum, "pay", this.state.domainName)
                    .then((response) => response.json())
                    .then((responsejson) => {
                        console.log("data", responsejson, i);
                        this.setState({ CompletedCustomerNum: this.state.CompletedCustomerNum + 1 });
                        if ((this.state.numberOfRowsInCsv - 1) >= i) {
                            this.ActivateOrPayASingleCustomer(i + 1, this.state.STBListData[i + 1]);
                        } else {

                            this.setState({ isSpinnerVisible: false, STBListData: [], CompletedCustomerNum: 0 });

                        }
                    })
                    .catch((err) => {
                        console.log("error", err);
                        var olddata = this.state.failedCSVData;
                        olddata.push(SingleSTBData);
                        this.setState({ failedCSVData: olddata, CompletedCustomerNum: this.state.CompletedCustomerNum + 1 });
                        if ((this.state.numberOfRowsInCsv - 1) >= i) {
                            this.ActivateOrPayASingleCustomer(i + 1, this.state.STBListData[i + 1]);
                        } else {



                            this.setState({ isSpinnerVisible: false, STBListData: [], CompletedCustomerNum: this.state.CompletedCustomerNum + 1 });

                        }
                        console.log("error 222", err);


                    });
            } else {
                // this.setState({CompletedCustomerNum: this.state.CompletedCustomerNum + 1});
                if ((this.state.numberOfRowsInCsv - 1) >= i) {
                    this.ActivateOrPayASingleCustomer(i + 1, this.state.STBListData[i + 1]);
                } else {
                    this.setState({ isSpinnerVisible: false, STBListData: [] });
                }
            }
        }
    }
    bulkPayActivate() {
        this.setState({ isSpinnerVisible: true });
        if (!this.state.isCSVOperation) {
            var arrayOfID = [];
            if (this.state.isCSVOperation) {
                var lengh = this.state.STBListData.length;
                // console.log("seleted", this.state.selectedSTB);

                for (var i = 0; i < lengh; i++) {
                    if (this.state.STBListData[i].toggleMode) {
                        var num = this.state.STBListData[i].Customernum;
                        console.log("customer Number", num)
                        arrayOfID.push(num);
                    }
                }

            } else {
                var lengh = this.state.selectedSTB.length;
                console.log("seleted", this.state.selectedSTB);

                for (var i = 0; i < lengh; i++) {
                    var num = this.state.selectedSTB[i].customerNo;
                    console.log("customer Number", num)
                    arrayOfID.push(num);
                }
            }
            console.log("Customer id", arrayOfID);
            const { lcoID, lcoPassword, domainName } = this.state;
            if (lengh) {
                API.bulkPayActivate(lcoID, lcoPassword, "bulkpay", arrayOfID, domainName)
                    .then((response) => response.json())
                    .then((responsejson) => {
                        console.log("data command", responsejson);
                        // if(responsejson.message == "processed"){

                        //     // this.getdata1();
                        // }
                        this.setState({ selectedSTB: [], totalAmount: 0, selected: [], isSuccess: true, isSpinnerVisible: false, STBListData: [] });
                        console.log("data of selected array", this.state.selectedSTB);

                    }).catch((error) => {
                        this.setState({ isError: true })
                    });
            } else {
                this.setState({ isEmpty: true, isSpinnerVisible: false })
            }
        } else {
            var floatBal = parseFloat(this.state.lcoBalance);
            var floatcsvAmount = parseFloat(this.state.csvAmount);
            if (floatBal >= floatcsvAmount) {
                var length1 = this.state.STBListData.length;
                console.log("lendth", length1);

                let count = 0;
                for (var m = 0; m < length1; m++) {
                    console.log("Toggel Mode", this.state.STBListData[m].toggleMode);
                    if (this.state.STBListData[m].toggleMode) {
                        count = count + 1;


                    }

                }
                var str = count.toString();
                this.setState({ noOfCustomerCSVSelect: str, selectedCSVCount: 4 });
                console.log("number of ticked STB", this.state.noOfCustomerCSVSelect);
                // this.disconnetSingleCustomer(0, this.state.STBListData[0]);
                this.ActivateOrPayASingleCustomer(0, this.state.STBListData[0]);
            } else {
                this.setState({ isSpinnerVisible: false, isLcoBalanceDialog: true });
            }
        }
    }

    disconnetSingleCustomer(i, SingleSTBData) {
        console.log("single data", SingleSTBData);
        this.setState({ isSpinnerVisible: false, isBulkCSVOperationRunning: true });
        if (SingleSTBData) {
            console.log("single data", SingleSTBData);
            if (SingleSTBData.toggleMode) {
                console.log("single data 1", SingleSTBData);

                const { lcoID, lcoPassword } = this.state;
                console.log("data in recurse function", i, SingleSTBData.Customernum);
                API.disconnect(lcoID, lcoPassword, "disconnect", SingleSTBData.Customernum, this.state.domainName)
                    .then((response) => response.json())
                    .then((responsejson) => {
                        console.log("data", responsejson, i);
                        this.setState({ CompletedCustomerNum: this.state.CompletedCustomerNum + 1 });
                        if ((this.state.numberOfRowsInCsv - 1) >= i) {
                            this.disconnetSingleCustomer(i + 1, this.state.STBListData[i + 1]);
                        } else {

                            this.setState({ isSpinnerVisible: false, STBListData: [], CompletedCustomerNum: 0 });

                        }
                    })
                    .catch((err) => {
                        var olddata = this.state.failedCSVData;
                        olddata.push(SingleSTBData);
                        this.setState({ failedCSVData: olddata, CompletedCustomerNum: this.state.CompletedCustomerNum + 1 });
                        if ((this.state.numberOfRowsInCsv - 1) >= i) {
                            this.disconnetSingleCustomer(i + 1, this.state.STBListData[i + 1]);
                        } else {



                            this.setState({ isSpinnerVisible: false, STBListData: [], CompletedCustomerNum: this.state.CompletedCustomerNum + 1 });

                        }
                        console.log("error 222", err);


                    });
            } else {
                // this.setState({CompletedCustomerNum: this.state.CompletedCustomerNum + 1});
                if ((this.state.numberOfRowsInCsv - 1) >= i) {
                    this.disconnetSingleCustomer(i + 1, this.state.STBListData[i + 1]);
                } else {
                    this.setState({ isSpinnerVisible: false, STBListData: [] });
                }
            }
        }
    }

    bulkDisconnect() {
        this.setState({ isSpinnerVisible: true });
        if (!this.state.isCSVOperation) {
            var arrayOfID = [];

            var lengh = this.state.selectedSTB.length;
            console.log("seleted", this.state.selectedSTB);

            for (var i = 0; i < lengh; i++) {
                var num = this.state.selectedSTB[i].customerNo;
                console.log("customer Number", num)
                arrayOfID.push(num);
            }

            console.log("Customer id", arrayOfID);
            const { lcoID, lcoPassword, domainName } = this.state;
            if (lengh) {
                API.bulkDisconnect(lcoID, lcoPassword, "bulkdeactivate", arrayOfID, domainName)
                    .then((response) => response.json())
                    .then((responsejson) => {
                        console.log("data Response", responsejson);
                        // if(responsejson.message == "processed"){

                        //     this.getdata1();
                        // }
                        this.setState({ selectedSTB: [], totalAmount: 0, isSuccess: true, selected: [], isSpinnerVisible: false, STBListData: [] });
                        console.log("data of selected array", this.state.selectedSTB);

                    }).catch((error) => {
                        this.setState({ isError: true, isSpinnerVisible: false });
                    });


            } else {
                this.setState({ isEmpty: true, isSpinnerVisible: false });
            }
        } else {
            var data = this.state.STBListData;

            this.setState({ ProcessDataList: data, ll: data });
            var length1 = this.state.STBListData.length;
            console.log("lendth", length1, this.state.STBListData, this.state.ProcessDataList, this.state.ll, data);

            let count = 0;
            for (var m = 0; m < length1; m++) {
                console.log("Toggel Mode", this.state.STBListData[m].toggleMode);
                if (this.state.STBListData[m].toggleMode) {
                    count = count + 1;


                }

            }
            var str = count.toString();
            this.setState({ noOfCustomerCSVSelect: str, selectedCSVCount: 4 });
            console.log("number of ticked STB", this.state.noOfCustomerCSVSelect);
            this.disconnetSingleCustomer(0, this.state.STBListData[0]);
        }
    }
    refreshSingleCustomer(i, SingleSTBData) {



        console.log("single data", SingleSTBData);
        this.setState({ isSpinnerVisible: false, isBulkCSVOperationRunning: true });
        if (SingleSTBData) {
            console.log("single data", SingleSTBData);
            if (SingleSTBData.toggleMode) {
                console.log("single data 1", SingleSTBData);

                const { lcoID, lcoPassword, domainName } = this.state;
                console.log("data in recurse function", i, SingleSTBData.Customernum);
                API.refreshCustomerData(lcoID, lcoPassword, "refresh", domainName, SingleSTBData.Customernum)
                    .then((response) => response.json())
                    .then((responsejson) => {
                        console.log("data", responsejson, i);
                        this.setState({ CompletedCustomerNum: this.state.CompletedCustomerNum + 1 });
                        if ((this.state.numberOfRowsInCsv - 1) >= i) {
                            this.refreshSingleCustomer(i + 1, this.state.refreshDataToProcess[i + 1]);
                        } else {

                            this.setState({ isSpinnerVisible: false, refreshDataToProcess: [], CompletedCustomerNum: 0, selectedSTB: [], totalAmount: 0, selectedItemsCount: 0 });

                        }
                    })
                    .catch((err) => {
                        var olddata = this.state.failedCSVData;
                        olddata.push(SingleSTBData);
                        this.setState({ failedCSVData: olddata, CompletedCustomerNum: this.state.CompletedCustomerNum + 1 });
                        if ((this.state.numberOfRowsInCsv - 1) >= i) {
                            this.refreshSingleCustomer(i + 1, this.state.refreshDataToProcess[i + 1]);
                        } else {



                            this.setState({ isSpinnerVisible: false, refreshDataToProcess: [], CompletedCustomerNum: this.state.CompletedCustomerNum + 1, selectedSTB: [], totalAmount: 0, selectedItemsCount: 0 });

                        }
                        console.log("error 222", err);


                    });
            } else {
                // this.setState({CompletedCustomerNum: this.state.CompletedCustomerNum + 1});
                if ((this.state.numberOfRowsInCsv - 1) >= i) {
                    this.refreshSingleCustomer(i + 1, this.state.refreshDataToProcess[i + 1]);
                } else {
                    this.setState({ isSpinnerVisible: false, refreshDataToProcess: [], selectedSTB: [], totalAmount: 0, selectedItemsCount: 0 });
                }
            }
        }

    }
    refreshDataAll() {
        this.setState({ isShowRefreshWarningDialog: false });
        if (!this.state.isCSVOperation) {
            console.log("Sele", this.state.selectedSTB);
            this.state.refreshDataToProcess = this.state.selectedSTB;
            for (var i = 0; i < this.state.refreshDataToProcess.length; i++) {
                this.state.refreshDataToProcess[i].toggleMode = true;
                this.state.refreshDataToProcess[i].Customernum = this.state.refreshDataToProcess[i].customerNo;

            }
            this.state.selectedSTB = [];
            this.setState({ selectedSTB: [], selected: [] });
            this.state.numberOfRowsInCsv = this.state.refreshDataToProcess.length;
            this.state.noOfCustomerCSVSelect = this.state.refreshDataToProcess.length.toString();
            console.log("stb csv", this.state.refreshDataToProcess);
            this.refreshSingleCustomer(0, this.state.refreshDataToProcess[0]);

        } else {
            var count = 0;
            var arr = [];

            console.log("stb csv", this.state.refreshDataToProcess);
            for (var j = 0; j < this.state.STBListData.length; j++) {
                if (this.state.STBListData[j].toggleMode) {
                    count += 1;
                    arr.push(this.state.STBListData[j]);
                }
            }
            this.setState({ refreshDataToProcess: arr });
            this.state.refreshDataToProcess = arr;
            this.state.numberOfRowsInCsv = this.state.refreshDataToProcess.length;
            this.state.noOfCustomerCSVSelect = arr.length.toString();
            this.refreshSingleCustomer(0, this.state.refreshDataToProcess[0]);
        }
    }
    payAllCheckFun() {
        this.setState({ isSpinnerVisible: true, isShowWarningPayCheck: false, })
        const { lcoID, lcoPassword, domainName } = this.state;
        API.payAllCheck(lcoID, lcoPassword, "payallcheck", domainName)
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response in check ", responseJson.Total);
                var total1 = responseJson.Total ? parseFloat(responseJson.Total).toFixed(2) : 0;
                if (this.state.lcoBalance < total1) {
                    console.log("lwo");
                    this.setState({ payAllRequireAmount: total1, isShowPayAllLowBalanceDialog: true, isSpinnerVisible: false });
                } else {
                    console.log("heigh");
                    this.setState({ payAllRequireAmount: total1, isSpinnerVisible: false, isShowWarningPayCheck: false, isShowSuccessPayCheck: true, isErrorInPayProcess: "" });
                }
            }).catch((error) => {
                console.log("error", error);
                this.setState({ isSpinnerVisible: false, isErrorInPayProcess: error.message });
            });
    }
    payAllFun() {
        this.setState({ isSpinnerVisible: true, isShowSuccessPayCheck: false })
        const { lcoID, lcoPassword, domainName } = this.state;
        API.payAll(lcoID, lcoPassword, "payall", domainName)
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("response in check ", responseJson);
                this.setState({ isSpinnerVisible: false, isErrorInPayProcess: "", isShowPayAllSuccessDialog: true });
                // this.getdata1();
            }).catch((error) => {
                console.log("error", error);
                this.setState({ isSpinnerVisible: false, isErrorInPayProcess: error.message });
            });

    }

    stbInversePaysto() {

        this.setState({ isSpinnerVisible: true, });
        var arr = [];
        var allow = true;
        if (this.state.isCSVOperation) {
            var STBListCopy = this.state.STBListData;
            var len = STBListCopy.length;
            for (var i = 0; i < len; i++) {
                if (STBListCopy[i].toggleMode) {
                    arr.push(STBListCopy[i].Customernum);
                }
            }


        } else {
            debugger;
            var selectedSTBData = this.state.selectedSTB;
            var len1 = selectedSTBData.length;
            console.log("length", len1, selectedSTBData, this.state.selectedSTB);
            for (var k = 0; k < len1; k++) {

                arr.push(selectedSTBData[k].customerNo);

            }

        }
        var lengthof = arr.length;
        var STBString = arr.join();
        console.log("String STB", STBString, arr);

        if (STBString && lengthof) {
            const { lcoID, lcoPassword, domainName } = this.state;
            API.inversePaySto(lcoID, lcoPassword, "custstoinvpay", STBString, domainName)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("Respone check", responseJson.Total);
                    var tot12 = responseJson.Total ? responseJson.Total : 0;
                    this.setState({ isSpinnerVisible: false, inversePayTopayAmount: tot12 });

                    if (parseFloat(tot12) <= parseFloat(this.state.lcoBalance)) {
                        debugger;
                        this.setState({ isShowSuccessInversePayReplay: true })
                    } else {
                        debugger;

                        this.setState({ isShowLowBalanceInInversePay: true, isShowWarningForInversePay: false });

                    }



                }).catch((error) => {
                    console.log("error in Inverse pay", error);
                    this.setState({ isSpinnerVisible: false });
                })
        } else {
            this.setState({ isSpinnerVisible: false, isEmpty: true });
        }
    }


    stbInversePayConfirm() {

        this.setState({ isSpinnerVisible: true, });
        var arr = [];
        if (this.state.isCSVOperation) {
            var STBListCopy = this.state.STBListData;
            console.log("csv data", this.state.STBListData);
            var len = STBListCopy.length;
            for (var i = 0; i < len; i++) {
                if (STBListCopy[i].toggleMode) {
                    arr.push(STBListCopy[i].Customernum);
                }
            }


        } else {
            debugger;
            var selectedSTBData = this.state.selectedSTB;
            var len1 = selectedSTBData.length;
            console.log("length", len1, selectedSTBData, this.state.selectedSTB);
            for (var k = 0; k < len1; k++) {

                arr.push(selectedSTBData[k].customerNo);

            }

        }
        var STBString = arr.join();
        console.log("String STB", STBString, arr);


        const { lcoID, lcoPassword, domainName } = this.state;
        API.inversePaySto(lcoID, lcoPassword, "custsinvpay", STBString, domainName)
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("Respone", responseJson);
                debugger;
                this.setState({ isSuccess: true, isSpinnerVisible: false, selectedSTB: [], isCSVOperation: false, STBListData: [] });



            }).catch((error) => {
                console.log("error in Inverse pay", error);
                this.setState({ isSpinnerVisible: false });
            })

    }
    removeSTB(custno) {
        debugger;

        let { selected, oldValue } = this.state;
        //oldValue=(parseFloat(oldDataList[index].topay).toFixed(2));
        console.log("selected stb : ", this.state.selected);
        for (var i = 0; i < this.state.selectedSTB.length; i++) {

            if (this.state.selected[i].customerNo == custno) {
                oldValue = parseFloat(this.state.totalAmount) - parseFloat(this.state.selectedSTB[i].amount);
                this.state.selected.splice(i, 1);

                this.setState({ selectedSTB: selected, totalAmount: oldValue.toFixed(2) });

                break;
            }

        }

        for (i = 0; i < this.state.customerList.length; i++) {
            if (this.state.customerList[i].Customernum == custno) {
                let oldDataList = this.state.customerList;
                let oldToggleMode = oldDataList[i].toggleMode;
                oldDataList[i].toggleMode = !oldToggleMode;
                this.setState({ customerList: oldDataList });
            }
        }
        this.setState({ selectedItemsCount: this.state.selectedSTB.length });
    }


    handleScroll(e) {
        const bottom = e.target.scrollHeight - Math.round(e.target.scrollTop) === e.target.clientHeight;
        console.log("bottom:", bottom)
        if (bottom) {
            console.log("e.target.scrollTop:", e.target.scrollTop)
            this.nextPageData(this.state.customerList, (this.state.pageCount + 1));
        }
    }

    toggleInCSVList(i) {
        let old = this.state.STBListData;
        let toggle = old[i].toggleMode;
        old[i].toggleMode = !toggle;
        this.setState({ STBListData: old });
        var len = this.state.STBListData.length;
        var total = 0;
        console.log("STB", this.state.STBListData);
        for (var k = 0; k < len; k++) {
            if (this.state.STBListData[k].toggleMode) {
                console.log("ok", total, i, this.state.STBListData[k].toggleMode);
                total = parseFloat(total) + parseFloat(this.state.STBListData[k].topay);
            }
        }
        console.log(total);
        this.setState({ csvAmount: total });
    }
    getLCO_Details() {
        debugger;
        this.setState({ isSpinnerVisible: true })
        const { lcoID, lcoPassword, domainName } = this.state;
        API.getLOC_Details(lcoID, lcoPassword, "lcodetail", domainName)
            .then((response) => response.json())
            .then((responsejson) => {
                this.setState({
                    lcoName: responsejson.lconame,
                    lcoBalance: responsejson.balance,
                    lcocode: responsejson.lcocode,
                    isShowMantainanceDialog: responsejson.maint,
                    maintText: responsejson.mainttext,
                    maintIMG: responsejson.maintimage

                });
                this.getdata1();
            }).catch((error) => {
                this.setState({ isSpinnerVisible: false });
            });
    }
    logOutFunc() {
        if (this.state.isloggedOut) {
            return <Redirect to="/login" />
        } else {
            return null;
        }
    }
    logOut() {

        localStorage.removeItem("userID");
        const username = localStorage.getItem("userID");
        if (!username) {
            this.setState({ isloggedOut: true });
        }
    }
    backTOTrandactions() {
        this.props.onMoveToTransactions();
    }

    view(data, stbLength) {
        if (data) {
            return (
                <a className="show-pack">View</a>
            )
        }
        else {
            return (
                <a></a>
            )
        }
    }
    isShowCheckBox(isProcess, i, data) {
        if (!isProcess) {
            return (
                // <Checkboxs

                //     onChange={() => { this.toggleChangeInCustomerAdd(i) }}
                //     textClassName="bouncechk__text"
                //     className="bouncechk__customClassName"
                //     text=''
                //     modifierClass='green'
                //     checked={data.toggleMode}
                // />
                <Checkboxs
                    style={{ fontSize: 20, color: "green" }}
                    color="green"

                    onClick={() => { this.toggleChangeInCustomerAdd(i) }}
                    checked={data.toggleMode} />
            );
        } else {
            return (
                <MdRefresh className="refresh-icons" onClick={() => { this.getdata1() }} style={{ cursor: "pointer" }} />
            );
        }
    }
    toggleInCSV() {
        this.setState({ isCSVOperation: !this.state.isCSVOperation, STBListData: [], numberOfRowsInCsv: 0 });

    }



    checkTheProcessIsEligible() {
        const { availablePack } = this.state;
        var type = this.state.whichType;
        var bal = parseInt(this.state.lcoBalance);
        var req = parseInt(this.state.requireAmountForPackages);
        var selectedPackIDArr = [];
        for (var data of availablePack) {
            if (data.toggleMode) {
                selectedPackIDArr.push(data.id);
            }
        }
        this.setState({ selectedPackID: selectedPackIDArr })
        if (selectedPackIDArr.length) {
            if (type == "add") {
                if (req < bal) {
                    this.setState({ isShowAddPackWarning: true });
                }
            } else {
                this.setState({ isShowRemovePackWarning: true });
            }
        } else {
            this.setState({ isEmpty: true });
        }
    }

    recursiveFuncForRemovePackageCSV(data) {
        var completed = this.state.noOfcompletedData;
        var total = this.state.noOfDataGoingToProcess;
        console.log("data", data);
        debugger;
        if (total >= completed) {
            const { lcoID, lcoPassword, domainName, selectedPackID } = this.state;

            API.removePackageAPI(lcoID, lcoPassword, data.Customernum, "bulkremovepackage", data.SNo, selectedPackID, this.state.domainName)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson);
                    debugger;
                    var n = this.state.noOfcompletedData;
                    this.setState({ noOfcompletedData: n + 1 });
                    if (responseJson.message) {


                        if ((n + 1) <= total) {
                            this.recursiveFuncForRemovePackageCSV(this.state.dataGoingToProcess[n + 1])
                        }
                    } else {
                        var data1 = this.state.unProcessedData;
                        data1.push({ result: responseJson.error });
                        this.setState({ unProcessedData: data1 });
                        if ((n + 1) <= total) {
                            this.recursiveFuncForRemovePackageCSV(this.state.dataGoingToProcess[n + 1])
                        }
                    }

                }).catch((error) => {
                    console.log("Error", error);
                })
        }
    }

    recursiveFuncForRemovePackage(data) {
        var completed = this.state.noOfcompletedData;
        var total = this.state.noOfDataGoingToProcess;
        console.log("data", data);
        debugger;
        if (total >= completed) {
            const { lcoID, lcoPassword, domainName, selectedPackID } = this.state;
            var STBno = data.stbs.length ? data.stbs[0].SNo : "null"
            API.addPackageAPI(lcoID, lcoPassword, data.customerNo, "bulkremovepackage", STBno, selectedPackID, this.state.domainName)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson);
                    debugger;
                    var n = this.state.noOfcompletedData;
                    this.setState({ noOfcompletedData: n + 1 });
                    if (responseJson.message) {


                        if ((n + 1) <= total) {
                            this.recursiveFuncForRemovePackage(this.state.dataGoingToProcess[n + 1])
                        }
                    } else {
                        var data1 = this.state.unProcessedData;
                        data1.push({ result: responseJson.error });
                        this.setState({ unProcessedData: data1 });
                        if ((n + 1) <= total) {
                            this.recursiveFuncForRemovePackage(this.state.dataGoingToProcess[n + 1])
                        }
                    }

                }).catch((error) => {
                    console.log("Error", error);
                })
        }
    }

    removePackageRec() {

        const { selectedSTB, STBListData } = this.state;
        var arr = [];
        if (this.state.isCSVOperation) {

            for (var item of this.state.STBListData) {
                if (item.toggleMode) {
                    arr.push(item)
                }
            }
        } else {
            arr = selectedSTB
        }
        this.state.dataGoingToProcess = arr;
        this.state.noOfDataGoingToProcess = arr.length;
        this.setState({ isShowProcessDialog: true, isShowPackDialog: false });
        console.log("data", this.state.dataGoingToProcess, this.state.noOfDataGoingToProcess);
        debugger;
        if (!this.state.isCSVOperation) {
            this.recursiveFuncForRemovePackage(this.state.dataGoingToProcess[0]);
        } else {
            this.recursiveFuncForRemovePackageCSV(this.state.dataGoingToProcess[0]);
        }

        // console.log();
    }

    recursiveFuncForAddPackageCSV(data) {
        var completed = this.state.noOfcompletedData;
        var total = this.state.noOfDataGoingToProcess;
        console.log("data", data);
        debugger;
        if (total >= completed) {
            const { lcoID, lcoPassword, domainName, selectedPackID } = this.state;

            API.addPackageAPI(lcoID, lcoPassword, data.Customernum, "bulkaddpackage", data.SNo, selectedPackID, this.state.domainName)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson);
                    debugger;
                    var n = this.state.noOfcompletedData;
                    this.setState({ noOfcompletedData: n + 1 });
                    if (responseJson.message) {


                        if ((n + 1) <= total) {
                            this.recursiveFuncForAddPackageCSV(this.state.dataGoingToProcess[n + 1])
                        }
                    } else {
                        var data1 = this.state.unProcessedData;
                        data1.push({ result: responseJson.error });
                        this.setState({ unProcessedData: data1 });
                        if ((n + 1) <= total) {
                            this.recursiveFuncForAddPackageCSV(this.state.dataGoingToProcess[n + 1])
                        }
                    }

                }).catch((error) => {
                    console.log("Error", error);
                })
        }
    }

    recursiveFuncForAddPackage(data) {
        var completed = this.state.noOfcompletedData;
        var total = this.state.noOfDataGoingToProcess;
        console.log("data", data);
        debugger;
        if (total >= completed) {
            const { lcoID, lcoPassword, domainName, selectedPackID } = this.state;
            var STBno = data.stbs.length ? data.stbs[0].SNo : "null"
            API.addPackageAPI(lcoID, lcoPassword, data.customerNo, "bulkaddpackage", STBno, selectedPackID, this.state.domainName)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson);
                    debugger;
                    var n = this.state.noOfcompletedData;
                    this.setState({ noOfcompletedData: n + 1 });
                    if (responseJson.message) {


                        if ((n + 1) <= total) {
                            this.recursiveFuncForAddPackage(this.state.dataGoingToProcess[n + 1])
                        }
                    } else {
                        var data1 = this.state.unProcessedData;
                        data1.push({ result: responseJson.error });
                        this.setState({ unProcessedData: data1 });
                        if ((n + 1) <= total) {
                            this.recursiveFuncForAddPackage(this.state.dataGoingToProcess[n + 1])
                        }
                    }

                }).catch((error) => {
                    console.log("Error", error);
                })
        }
    }
    addPackageRec() {

        const { selectedSTB, STBListData } = this.state;
        var arr = [];
        if (this.state.isCSVOperation) {

            for (var item of this.state.STBListData) {
                if (item.toggleMode) {
                    arr.push(item)
                }
            }
        } else {
            arr = selectedSTB
        }
        this.state.dataGoingToProcess = arr;
        this.state.noOfDataGoingToProcess = arr.length;
        this.setState({ isShowProcessDialog: true, isShowPackDialog: false });
        console.log("data", this.state.dataGoingToProcess, this.state.noOfDataGoingToProcess);
        debugger;
        if (!this.state.isCSVOperation) {
            this.recursiveFuncForAddPackage(this.state.dataGoingToProcess[0]);
        } else {
            this.recursiveFuncForAddPackageCSV(this.state.dataGoingToProcess[0]);
        }

        // console.log();
    }

    showCaseList(type) {
        this.setState({ isSpinnerVisible: true, whichType: type });
        const { lcoID, lcoPassword, domainName } = this.state;
        API.casList(lcoID, lcoPassword, domainName)
            .then((response) => response.json())
            .then((responsejson) => {
                console.log("response", responsejson);
                this.setState({ isSpinnerVisible: false, isShowCASListDialog: true, casList: responsejson.cas })
                debugger;
            }).catch((error) => {
                console.warn("error", error);
                this.setState({ isSpinnerVisible: false })
            })
    }
    getPack(cas) {
        this.setState({ isSpinnerVisible: true, whichCAS: cas, isShowCASListDialog: false })
        const { lcoID, lcoPassword, domainName, whichCAS } = this.state;
        API.getPackList(lcoID, lcoPassword, cas, domainName)
            .then((response) => response.json())
            .then((responsejson) => {
                console.log("response", responsejson);
                debugger;
                var listArray = [];
                for (var data of responsejson.products) {
                    data.toggleMode = false;
                    listArray.push(data);
                }
                this.setState({ availablePack: listArray, availablePackSearchResult: listArray, isShowPackDialog: true, isSpinnerVisible: false });
                debugger;
            }).catch((error) => {
                console.warn("error", error);
                this.setState({ isSpinnerVisible: false });
                debugger;
            })
    }

    packageSearchInPackDialog(text) {
        const { availablePack } = this.state;
        var lowerCaseText = text.toString().toLowerCase();
        var result = [];
        for (var data of availablePack) {
            var name = data.name.toString().toLowerCase();
            var n = name.search(lowerCaseText)
            if (n != -1) {
                result.push(data)
            }
        }
        this.setState({ availablePackSearchResult: result });
    }
    daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }


    calculateAmount() {

        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        var numberOfDayInMonth = this.daysInMonth(parseInt(mm), parseInt(yyyy))
        var remaingDay = numberOfDayInMonth - parseInt(dd);
        var len = 0;
        if (this.state.isCSVOperation) {
            for (var item of this.state.STBListData) {
                if (item.toggleMode) {
                    len = len + 1;
                }
            }

        } else {
            len = this.state.selectedSTB.length;
        }

        var availablePackList = this.state.availablePack;
        // if(this.state.whichType == "add"){
        // var resultAmount=0;
        var amount = 0;
        for (var i in availablePackList) {
            if (availablePackList[i].toggleMode) {
                amount = parseFloat(amount) + parseFloat(availablePackList[i].baseprice)
            }
        }

        amount = ((amount / numberOfDayInMonth) * remaingDay).toFixed(2);
        amount = (amount * len).toFixed(2);
        this.setState({ requireAmountForPackages: amount })
        console.log("data pro", amount);
        debugger;

        // }
        // else{
        //     for(var i in availablePackList){
        //         if(availablePackList[i].id ){
        //             var toggle = availablePackList[i].toggleMode;
        //           availablePackList[i].toggleMode = !toggle;
        //           console.log("data",availablePackList[i])
        //           debugger;
        //           break;
        //         }
        //       }
        // }
    }
    toggleChangePackageSelection(id) {
        // const {availablePack,availablePackSearchResult}= this.state; 
        var availablePackList = this.state.availablePack;
        var availablePackSearchResultList = this.state.availablePackSearchResult;
        for (var i in availablePackList) {
            if (availablePackList[i].id == id) {
                var toggle = availablePackList[i].toggleMode;
                availablePackList[i].toggleMode = !toggle;
                console.log("data", availablePackList[i])
                debugger;
                break;
            }
        }
        // for(var j in availablePackSearchResultList){
        //     if(availablePackSearchResultList[j].id == id){
        //         var mode = availablePackSearchResultList[j].toggleMode;
        //         availablePackSearchResultList[j].toggleMode = !mode;
        //         console.log("data",availablePackSearchResultList[j],!mode);
        //         debugger;
        //       break;
        //     }
        //   }
        console.log("data in packages", availablePackList);
        debugger;
        this.setState({ availablePack: availablePackList });
        this.calculateAmount();

    }
    isShowCSVOption() {
        if (this.state.isCSVOperation) {
            return (
                <div>
                    <CSVReader
                        cssClass="react-csv-input"
                        onFileLoaded={e => { this.handleCSVFileUpload(e) }}
                    />
                    <label style={{ fontSize: 15 }}>Total customers in CSV: <label style={{ fontSize: 18, fontWeight: "bold", color: "#d61818" }}>{this.state.STBListData.length}</label></label>
                    <CSVLink
                        filename={`Bulk_Operation(${this.state.lcoID}).csv`}
                        data={this.csvData} style={{ display: "inline-block", marginLeft: 15 }}>
                        Download sample file
                        </CSVLink>
                </div>
            );
        } else {
            return null;
        }
    }

    isPayAllVisibleBtn() {
        if (!this.state.isCSVOperation) {
            return (
                <Button
                    style={{ marginRight: 15, paddingRight: 20, paddingLeft: 20 }}
                    variant="warning" onClick={() => { this.setState({ isShowWarningPayCheck: true }) }}>Pay All</Button>
            );
        } else {
            return null;
        }
    }
    whichTableToShow() {
        if (!this.state.isCSVOperation) {

            return (
                <table className="table table-striped">
                    <thead>
                        <th style={{ width: "3%" }}></th>
                        <th>Name</th>

                        <th>STB</th>
                        <th>Connection Status</th>
                        <th>Paid / Unpaid</th>
                        <th>Amount</th>
                        <th>Cas</th>
                        <th>Type</th>
                        <th>Products</th>
                    </thead>
                    <tbody className="total-records">
                        {this.state.customerList.map((data, i) => {
                            return (
                                <tr>
                                    <td style={{ width: "3%" }}>

                                        {this.isShowCheckBox(data.process, i, data)}
                                    </td>



                                    <td>{data.name}</td>

                                    <td>{data.stbs.map((data1, i) => {
                                        return (
                                            <label>{data1.SNo}</label>
                                        );
                                    })}
                                    </td>
                                    <td>{data.connectionstatus}</td>
                                    <td>{data.paid}</td>
                                    <td>{data.topay}</td>
                                    <td>{data.stbs.map((data1, i) => {
                                        return (
                                            <label>{data1.CAS}</label>
                                        );
                                    })}
                                    </td>
                                    <td>{data.stbs.map((data1, i) => {
                                        return (
                                            <label>{data1.type}</label>
                                        );
                                    })}
                                    </td>

                                    <td style={{ maxLines: 2, height: 40, overflow: "hidden", textOverflow: "ellipsis" }}>
                                        <div style={{ height: 40, overflow: "hidden" }}>
                                            {data.stbs.map((dataProduct, i) => {

                                                return (
                                                    // <div className="pack-div">
                                                    //     {this.view(dataProduct.pack.length, data.stbs.lengh)}
                                                    <ul >
                                                        <a>{dataProduct.pack.map((datap, i) => {

                                                            return (



                                                                <li className="data-product">{datap.name},</li>


                                                            )

                                                        })}

                                                        </a>
                                                    </ul>
                                                    // </div>
                                                )

                                            })}

                                        </div>
                                        {data.stbs.length ? <label onClick={() => {
                                            this.setState({ selectedPackageData: data.stbs, isShowPackageDialog: true })
                                        }} style={{ color: GeneralValues.mustard, textDecoration: "underline" }}>More</label> : null}
                                    </td>
                                    {/* <td>{data.stbs.map((dataProduct, i) => {

                                        return (
                                            // <div className="pack-div">
                                            //     {this.view(dataProduct.pack.length, data.stbs.lengh)}
                                            <ul >
                                                <a>{dataProduct.pack.map((datap, i) => {

                                                    return (



                                                        <li className="data-product">{datap.name},</li>


                                                    )

                                                })}

                                                </a>
                                            </ul>
                                            // </div>
                                        )

                                    })}
                                    </td> */}
                                    {/* <td>{data.stbs.map((dataProduct, i) => {

                                        return (
                                            <div className="pack-div">
                                                {this.view(dataProduct.pack.length, data.stbs.lengh)}
                                                <ul>
                                                    <a>{dataProduct.pack.map((datap, i) => {

                                                        return (



                                                            <li>{datap.name}</li>


                                                        )

                                                    })}

                                                    </a>
                                                </ul>
                                            </div>
                                        )

                                    })}
                                    </td> */}

                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            );
        } else {
            var data2 = [];
            data2 = this.state.STBListData.pack;
            console.log("Packages", data2);
            return (
                <table className="table table-striped">


                    <thead>
                        <th style={{ width: "3%" }}></th>
                        <th>Name</th>
                        <th style={{ width: "17%" }}>Customer No</th>

                        <th>STB </th>
                        <th>Connection Status</th>

                        <th>Amount</th>

                    </thead>
                    <tbody className="total-records">
                        {this.state.STBListData.map((data, i) => {
                            return (
                                <tr>
                                    <td style={{ width: "3%" }}>

                                        <Checkboxs
                                            textClassName="bouncechk__text"
                                            className="bouncechk__customClassName"
                                            text=''
                                            modifierClass='green'
                                            onChange={() => { this.toggleInCSVList(i) }}
                                            checked={data.toggleMode}
                                        />
                                    </td>



                                    <td>{data.Name}</td>
                                    <td style={{ width: "17%" }}>{data.Customernum}</td>
                                    <td>{data.SNo}
                                    </td>
                                    <td>{data.status}</td>

                                    <td>{data.topay}</td>
                                    {/* <td>
                                            <div className="pack-div">
                                               
                                                <ul>
                                                    <a>{data2.map((datap, i) => {

                                                        return (



                                                            <li>{datap}</li>


                                                        )

                                                    })}

                                                    </a>
                                                </ul>
                                            </div>
                                       
                                    </td> */}

                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            );
        }
    }

    isShowSelectedItemCount() {
        if (!this.state.isCSVOperation) {
            return (
                <label>Selected Items : <span style={{ fontSize: 18, color: "red" }}>{this.state.selectedItemsCount}</span></label>
            );
        } else {
            return null;
        }
    }
    stockListSearch(text) {

        var { lcoID, lcoPassword, domainName, inStockListSearchText } = this.state;
        if (text.length > 2) {
            this.setState({ inStockDataList: [], isShowInStockList: true, isShowStockLoader: true, noData: "" });

            API.stockListSearch(lcoID, lcoPassword, "liststock", text, 1, domainName)
                .then((response) => response.json())
                .then((responseJson) => {
                    console.log("response", responseJson);
                    if (responseJson.count) {


                        this.setState({ inStockDataList: responseJson.content, isShowInStockList: true, inStockCurrentPage: 1, totalCountInStock: responseJson.count, isShowStockLoader: false });
                        console.log("In stock list", this.state.inStockDataList);
                    } else {
                        this.setState({ inStockDataList: [], isShowStockLoader: false, noData: "No data found!" });
                    }
                });
        } else {
            this.setState({ isShowInStockList: false, isShowInStockList: false, isShowStockLoader: false, noData: "" });
        }
    }
    getSTBDetails(item) {
        console.log("item", item);
        if (item.Customer) {
            this.setState({ spinnerVisible: true });
            const { lcoID, lcoPassword, domainName, searchText } = this.state;
            API.STBSearch(lcoID, lcoPassword, "stbsearch", item.SNo, domainName)
                .then((response) => response.json())
                .then((responsejson) => {
                    console.log("response", responsejson);
                    if (responsejson.message == "no data") {
                        this.setState({ spinnerVisible: false });
                    } else {
                        localStorage.setItem("currentlco", item.Lcocode);
                        this.setState({ spinnerVisible: false });
                        this.props.moveToCustomerDetails("customerdetails", responsejson, this.state.searchText);
                    }
                });
        }
        else {
            this.setState({ isShowNodataDialog: true })
        }

    }
    render() {
        console.log("Seleceted STB Data", this.state.selectedSTB);
        return (

            <Rootcontainer onScroll={(e) => this.handleScroll(e)}>
                <LoadingOverlay


                    className={this.state.isSpinnerVisible ? "displaySpinner" : ""}
                    overlay={true}
                    active={this.state.isSpinnerVisible}
                    spinner={<img src={loadinggif} className="spinnerContent" color="green" style={{ marginLeft: 100, }} />}

                ></LoadingOverlay>
                <Drawer

                    open={this.state.isShowLogOutDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 15, fontWeight: 'bold', }}>Do you want <span style={{ fontSize: "1.5em", color: "red" }}>Log out</span> ?</div>
                            <Undraw className="undrawimg" name="monitor" />
                            <Button
                                style={{ marginLeft: 20 }}
                                className="success" className="mt-3 pull-right"
                                onClick={() => {


                                    this.setState({ isShowLogOutDialog: false })

                                }}
                            >Cancel</Button>

                            <Button
                                style={{ backgroundColor: "red" }}
                                className="danger" className="mt-3 pull-right"
                                onClick={() => {

                                    // this.props.hindNodataDialog();
                                    this.logOut();

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>
                <Drawer

                    open={this.state.isSuccess}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >

                        <Card.Body>
                            <div style={{ fontSize: 18, fontWeight: 'bold' }}>Request sent !</div>

                            <Button variant="success" className="mt-3 pull-right"
                                onClick={() => {

                                    this.setState({ isSuccess: false, selectedItemsCount: 0, selectedSTB: [], csvAmount: 0, totalAmount: 0 });
                                    this.getLCO_Details();
                                    this.getdata1()

                                }}
                            >OK</Button>

                        </Card.Body>
                    </Card>

                </Drawer>

                <DialogBoxs
                    numberOfRowsInCsv={this.state.numberOfRowsInCsv}
                    CompletedCustomerNum={this.state.CompletedCustomerNum}
                    isBulkCSVOperationRunning={this.state.isBulkCSVOperationRunning}
                    hindBulkCSVOperationRunning={this.hindBulkCSVOperationRunning.bind(this)}
                    noOfCustomerCSVSelect={this.state.noOfCustomerCSVSelect}
                    isShowFailedCSVDialog={this.state.isShowFailedCSVDialog}
                    failedCSVData={this.state.failedCSVData}
                    hindfailedCSVDialog={this.hindfailedCSVDialog.bind(this)}

                    isShowWarningPayCheck={this.state.isShowWarningPayCheck}
                    isShowSuccessPayCheck={this.state.isShowSuccessPayCheck}
                    hindPayAllCheckSuccessDialog={this.hindPayAllCheckSuccessDialog.bind(this)}
                    payAllCheckFun={this.payAllCheckFun.bind(this)}
                    payAllCheckMessage={this.state.payAllCheckMessage}
                    hindPayAllWarningDialog={this.hindPayAllWarningDialog.bind(this)}
                    payAllFun={this.payAllFun.bind(this)}

                    hindPayAllSuccessDialog={this.hindPayAllSuccessDialog.bind(this)}
                    isShowPayAllSuccessDialog={this.state.isShowPayAllSuccessDialog}
                    getLCO_Details={this.getLCO_Details.bind(this)}
                    hindLowBalanceDialog={this.hindLowBalanceDialog.bind(this)}
                    isLcoBalanceDialog={this.state.isLcoBalanceDialog}
                    isShowWarningForInversePay={this.state.isShowWarningForInversePay}
                    hindWarningInversePayDialog={this.hindWarningInversePayDialog.bind(this)}
                    stbInversePaysto={this.stbInversePaysto.bind(this)}
                    isShowSuccessInversePayReplay={this.state.isShowSuccessInversePayReplay}
                    hindShowSuccessInversePayReplay={this.hindShowSuccessInversePayReplay.bind(this)}
                    inversePayTopayAmount={this.state.inversePayTopayAmount}
                    stbInversePayConfirm={this.stbInversePayConfirm.bind(this)}
                    isShowLowBalanceInInversePay={this.state.isShowLowBalanceInInversePay}
                    hindLowBalanceInInversePay={this.hindLowBalanceInInversePay.bind(this)}
                    lcoBalance={this.state.lcoBalance}
                    hindCSVFileUploadDialog={this.hindCSVFileUploadDialog.bind(this)}
                    isShowCSVFileUploadDialog={this.state.isShowCSVFileUploadDialog}
                    noOfCSVFileUploaded={this.state.noOfCSVFileUploaded}
                    // hindPayAllLowBalanceDialog={this.hindPayAllLowBalanceDialog(this)}
                    isShowPayAllLowBalanceDialog={this.state.isShowPayAllLowBalanceDialog}
                    payAllRequireAmount={this.state.payAllRequireAmount}
                    hindRefreshWarningDialog={this.hindRefreshWarningDialog.bind(this)}
                    isShowRefreshWarningDialog={this.state.isShowRefreshWarningDialog}
                    refreshDataAll={this.refreshDataAll.bind(this)}
                    bulkPayActivate={this.bulkPayActivate.bind(this)}
                    hindWarningForPayNow={this.hindWarningForPayNow.bind(this)}
                    isShowWarningForPayNow={this.state.isShowWarningForPayNow}
                    isShowWarningForActivate={this.state.isShowWarningForActivate}
                    hindWarningForActivate={this.hindWarningForActivate.bind(this)}
                    isShowWarningForDeactivate={this.state.isShowWarningForDeactivate}
                    bulkDisconnect={this.bulkDisconnect.bind(this)}
                    hindWarningForDeactivate={this.hindWarningForDeactivate.bind(this)}
                    STBSearchFailedData={this.state.STBSearchFailedData}
                    isShowUnprocessedData={this.state.isShowUnprocessedData}
                    hindUnprocessedData={this.hindUnprocessedData.bind(this)}
                    un={this.state.un}
                    isShowPackAddOrRemoveDialog={this.state.isShowPackAddOrRemoveDialog}
                    hindPackAddOrRemoveDialog={this.hindPackAddOrRemoveDialog.bind(this)}
                    showCaseList={this.showCaseList.bind(this)}
                    isShowCASListDialog={this.state.isShowCASListDialog}
                    casList={this.state.casList}
                    hindCASListDialog={this.hindCASListDialog.bind(this)}
                    isShowPackDialog={this.state.isShowPackDialog}
                    hindPackDialog={this.hindPackDialog.bind(this)}
                    availablePack={this.state.availablePack}
                    getPack={this.getPack.bind(this)}
                    availablePackSearchResult={this.state.availablePackSearchResult}
                    packageSearchInPackDialog={this.packageSearchInPackDialog.bind(this)}
                    toggleChangePackageSelection={this.toggleChangePackageSelection.bind(this)}
                    whichType={this.state.whichType}
                    requireAmountForPackages={this.state.requireAmountForPackages}
                    EmptyErrorInPackageDialog={this.state.EmptyErrorInPackageDialog}
                    checkTheProcessIsEligible={this.checkTheProcessIsEligible.bind(this)}
                    isShowAddPackWarning={this.state.isShowAddPackWarning}
                    hindAddPackWarning={this.hindAddPackWarning.bind(this)}
                    isShowProcessDialog={this.state.isShowProcessDialog}
                    hindProcessDialog={this.hindProcessDialog.bind(this)}
                    noOfcompletedData={this.state.noOfcompletedData}
                    noOfDataGoingToProcess={this.state.noOfDataGoingToProcess}
                    unProcessedData={this.state.unProcessedData}
                    hindUnProcessedDataListDialog={this.hindUnProcessedDataListDialog.bind(this)}
                    isShowUnProcessedDataListDialog={this.state.isShowUnProcessedDataListDialog}
                    addPackageRec={this.addPackageRec.bind(this)}
                    isShowRemovePackWarning={this.state.isShowRemovePackWarning}
                    hindRemovePackWarning={this.hindRemovePackWarning.bind(this)}
                    removePackageRec={this.removePackageRec.bind(this)}
                    isShowMantainanceDialog={this.state.isShowMantainanceDialog}
                    maintIMG={this.state.maintIMG}
                    maintText={this.state.maintText}
                    getLOC_Details={this.getLCO_Details.bind(this)}
                    selectedPackageData={this.state.selectedPackageData}
                    isShowPackageDialog={this.state.isShowPackageDialog}
                    hindPackageDetailDialog={this.hindPackageDetailDialog.bind(this)}



                />


                <Drawer

                    open={this.state.isError}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: 'bold' }}>Unable to Process </div>

                            <Button variant="danger"
                                onClick={() => {

                                    this.setState({ isError: false });
                                    this.getLCO_Details();
                                }}
                            >OK</Button>

                        </Card.Body>
                    </Card>

                </Drawer>


                <Drawer

                    open={this.state.isEmpty}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: 'bold' }}>Unable to Process Empty List</div>

                            <Button className="danger" className="mt-3 pull-right"
                                onClick={() => {

                                    this.setState({ isEmpty: false });

                                }}
                            >OK</Button>

                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.state.isShowPayAllLowBalanceDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18, fontWeight: 'bold' }}>Require Amount : <label style={{ color: "red" }}> {this.state.payAllRequireAmount}</label>  </div>
                            <div style={{ fontSize: 18, fontWeight: 'bold' }}>Lco Balance : <label style={{ color: "red" }}> {this.state.lcoBalance}</label>  </div>
                            <div style={{ fontSize: 18, fontWeight: 'bold', color: "red" }}>Low Balance !</div>

                            <Button

                                className="success" className="mt-3 pull-right"
                                onClick={() => {
                                    this.hindPayAllLowBalanceDialog();

                                }}
                            >OK</Button>




                        </Card.Body>
                    </Card>

                </Drawer>



                <div className="header-div">

                    <h3 className="" style={{ marginLeft: '98px',marginRight:"-20px",textTransform:"uppercase" }}>Bulk Operations</h3>
                    <img
                        onClick={() => { this.props.moveToDashBoard() }}
                        src={GeneralValues.circlelogo} style={{ float: "left", marginLeft: " -180px" }} className="logo-img" alt="logo" />

                    <MdPowerSettingsNew onClick={() => { this.setState({ isShowLogOutDialog: true }) }} value="Logout" className="fontSize login" />
                    <input type="text" text="Search" placeholder="Global Search..."
                        onChange={(e) => {
                            this.setState({ inStockListSearchText: e.target.value })
                            this.stockListSearch(e.target.value);
                        }}

                        value={this.state.inStockListSearchText}
                        className="input-STB"
                        style={{ backgroundColor: "transparent", borderColor: "transparent", borderBottom: "2px solid white", color: "white", textAlign: "center", fontSize: 15, borderRight: "2px solid white", fontWeight: "bold", alignSelf: "end", marginLeft: "50%" }} />
                    <div style={{
                        position: "absolute",
                        display: this.state.isShowInStockList ? "block" : "none",
                        listStyle: "none",
                        backgroundColor: "white",
                        padding: 0,
                        boxShadow: "0px 1px 3px #e9b42a",
                        zIndex: 9,
                        width: "22vw",
                        top: 60,
                        overflow: "auto",
                        maxHeight: "30vh",
                        marginLeft: "65%"

                    }}
                        onScroll={(e) => this.handleScroll(e)}>
                        <label style={{ fontSize: 15, fontWeight: "bold", color: "rgb(26, 172, 195)", marginLeft: 10 }}>List:</label>
                        <div style={{ textAlign: "center", color: "gray" }}>{this.state.noData}</div>
                        <ul style={{ listStyle: "none", padding: 10 }}>

                            {this.state.inStockDataList.map((item, i) => {

                                return (


                                    <li className="search-list"
                                        onClick={() => { this.getSTBDetails(item) }}
                                        style={{ fontSize: 15, color: "black", lineHeight: 2, borderBottom: "1px solid rgba(26, 172, 195,0.5)", cursor: "pointer" }}>
                                        <div>
                                            <lable style={{ display: "block" }}>
                                                Name:
   {item.Customer ? item.Customer : <FaPlus style={{
                                                    background: " #0a3f65",
                                                    color: "white", width: "20px", height: "20px", padding: "2px", borderRadius: "10px", marginLeft: "8px"
                                                }} />}

                                                <FaCircle style={{
                                                    display: item.status ? "inline-block" : "none",
                                                    height: "12px", float: "right", marginTop: "8px",
                                                    color: item.status == "Live" ? "green" : "red",
                                                }} />


                                            </lable>

                                            <lable style={{ display: "block" }}>STB: {item.SNo}

                                                <div style={{
                                                    fontSize: "12px", display: item.paid ? "inline-block" : "none", float: "right", color: "white",
                                                    padding: "0px 7px", height: "25px",
                                                    backgroundColor: item.paid === "paid" ? "green" : "red",
                                                }}>{item.paid}  </div>
                                            </lable>

                                            <lable style={{ display: "block", fontSize: "12px" }}>Lco: {item.Lconame}

                                                <div style={{ fontSize: "12px", display: "inline-block", float: "right", color: "red" }}>({item.Lcocode})
  </div>
                                            </lable>
                                        </div>
                                    </li>

                                );
                            })}
                        </ul>

                        <Loader
                            visible={this.state.isShowStockLoader}
                            style={{ textAlign: "center", marginBottom: 10, }}
                            type="ThreeDots"
                            color="#00BFFF"
                            height="10"
                            width="20"
                        />
                    </div>

                </div>
                <RootDiv>
                    <RootLeft>
                        <RootTitle>
                            Name :
                    </RootTitle>
                    
                        <RootValue
                            style={{ cursor: "pointer",marginLeft:5 }}
                            onClick={() => { this.props.onClickProfile() }} >
                           {" "}{this.state.lcoName}

                        </RootValue>
                        <div className="lcocode">

                            <span style={{ color: "black" }}>Partner code:</span> {this.state.lcocode}
                </div>
                    </RootLeft>

                    <RootRight>
                        <RootTitle>
                            Balance :
</RootTitle>

                        <RootValue>
                            {'\u20B9'} {this.state.lcoBalance}
                        </RootValue>

                    </RootRight>
                </RootDiv>

                <MainContainer>
                    <div>
                        <label style={{ alignSelf: "center", marginLeft: "35%", color: "red" }}>{this.state.isErrorInPayProcess}</label>
                    </div>

                    <ButtonToolbar className="pull-right button-box">
                        <div className="search-div">
                            <input type="search" placeholder="search..." value={this.state.defauleValue} onChange={(e) => { this.setState({ searchText: e.target.value, firstLoad: e.target.value, defauleValue: e.target.value }) }}
                                onKeyPress={(e) => {

                                    if (e.keyCode == 13 || e.which == 13) {
                                        this.getdata1();
                                    }
                                }} />
                            <MdSearch />

                        </div>
                        {/* <DialogBoxs
                            isShowWarningPayCheck={this.state.isShowWarningPayCheck}
                            isShowSuccessPayCheck={this.state.isShowSuccessPayCheck}
                            hindPayAllCheckSuccessDialog={this.hindPayAllCheckSuccessDialog.bind(this)}
                            payAllCheckFun={this.payAllCheckFun.bind(this)}
                            payAllCheckMessage={this.state.payAllCheckMessage}
                            hindPayAllWarningDialog={this.hindPayAllWarningDialog.bind(this)}
                            payAllFun={this.payAllFun.bind(this)}
                            hindPayAllSuccessDialog={this.hindPayAllSuccessDialog.bind(this)}
                            isShowPayAllSuccessDialog={this.state.isShowPayAllSuccessDialog}
                        /> */}
                        <div className="addon-price">Total Amount: <label style={{ color: "red" }}>{'\u20B9'} {this.state.isCSVOperation ? (this.state.csvAmount).toFixed(2) : this.state.totalAmount}</label></div>
                        <div className="btn-align-right mt-1">
                            {this.isPayAllVisibleBtn()}
                            <Button
                                style={{ backgroundColor: "#AA6C39", borderColor: "#AA6C39" }}
                                className="mr-3" onClick={() => { this.setState({ isShowWarningForInversePay: true }) }}>Inverse Pay</Button>
                            <Button
                                style={{ backgroundColor: "#363377", borderColor: "#363377" }}
                                className="mr-3" onClick={() => { this.setState({ isShowRefreshWarningDialog: true }) }}>Refresh</Button>
                            <Button

                                variant="success" className="mr-3" onClick={() => {
                                    if (this.state.isCSVOperation) {
                                        this.setState({ isShowWarningForActivate: true })
                                    } else {

                                        if (this.state.selectedSTB.length) {
                                            this.setState({ isShowWarningForPayNow: true })
                                        } else {
                                            this.setState({ isEmpty: true });
                                        }
                                    }
                                }}>Pay Now</Button>
                            <Button variant="info" className="mr-3" onClick={() => {
                                if (this.state.isCSVOperation) {
                                    this.setState({ isShowWarningForActivate: true })
                                } else {
                                    if (this.state.selectedSTB.length) {
                                        this.setState({ isShowWarningForActivate: true })
                                    } else {
                                        this.setState({ isEmpty: true });
                                    }
                                }
                            }}>Activate</Button>
                            <Button variant="danger" onClick={() => {
                                if (this.state.isCSVOperation) {
                                    this.setState({ isShowWarningForDeactivate: true })
                                } else {
                                    if (this.state.selectedSTB.length) {
                                        this.setState({ isShowWarningForDeactivate: true })
                                    } else {
                                        this.setState({ isEmpty: true });
                                    }
                                }
                            }}>Deactivate</Button>

                            <Button
                                style={{ backgroundColor: "#582A72", borderColor: "#582A72", marginLeft: 15 }}
                                onClick={() => {
                                    if (this.state.isCSVOperation) {
                                        if (this.state.STBListData.length) {
                                            this.setState({ isShowPackAddOrRemoveDialog: true })
                                        } else {
                                            this.setState({ isEmpty: true });
                                        }

                                    } else {
                                        if (this.state.selectedSTB.length) {
                                            this.setState({ isShowPackAddOrRemoveDialog: true })
                                        } else {
                                            this.setState({ isEmpty: true });
                                        }
                                    }
                                }}>Package</Button>
                        </div>
                        <div style={{ marginLeft: "80%", postion: "relative", marginTop: 50 }}>

                            <Checkboxs
                                textClassName="bouncechk__text"
                                className="bouncechk__customClassName"
                                text='CSV Operation'
                                modifierClass='green'
                                onChange={() => { this.toggleInCSV() }}
                                checked={this.state.isCSVOperation}
                                style={{ display: "inline", float: 'left', marginRight: 10 }}
                            />
                            <div style={{ display: "inline", float: 'left' }} >CSV Operation</div>

                            {this.isShowCSVOption()}
                        </div>
                    </ButtonToolbar>
                    {this.isShowSelectedItemCount()}
                    <SelectedDiv className="selected-div">

                        {this.state.isCSVOperation ? null : this.state.selectedSTB.map((data, i) => {
                            return (

                                <SelectedPackage className="mr-3">{data.name} | {'\u20B9'} {data.amount}<MdClose onClick={(e) => this.removeSTB(data.customerNo)} /></SelectedPackage>


                            );
                        }
                        )}
                    </SelectedDiv>
                    {this.whichTableToShow()}

                    <Loader
                        visible={this.state.isShowLoader}
                        style={{ textAlign: "center" }}
                        type="ThreeDots"
                        color="#00BFFF"
                        height="20"
                        width="100"
                    />

                </MainContainer>

                {this.logOutFunc()}

            </Rootcontainer>
        )
    }
}