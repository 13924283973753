import React from 'react';
import Drawer from 'react-drag-drawer';
import './index.css';
import Undraw from 'react-undraw';
import { Button, ButtonGroup, Container, ButtonToolbar, Jumbotron, Card } from 'react-bootstrap';
export default class DialogBoxs extends React.Component {
    constructor(props){
        super(props);
        this.state={
            selectedLco:{},
            isShowTransferWarning:false
        }
    }
    showOkBtnInProcessing() {
        if (this.props.noOfcompletedData == this.props.noOfDataGoingToProcess) {
            return (
                <Button
                    style={{ backgroundColor: this.props.unProcessedData.length ? "red":"green", borderColor: "green", marginRight: 15 }}
                    className="mt-3 pull-right"
                    onClick={() => {

                        this.props.hindProcessDialog();

                    }}
                >{this.props.unProcessedData.length ? "Show Unprocess STB" : "OK"}</Button>
            );
        } else {
            return null;
        }
    }
  isShowLcoSearchResult(){
      
      if(this.props.lcoSearchResult.length){
          return(
            <div style={{maxHeight:"40vh",overflow:"auto"}}>
            {this.props.lcoSearchResult.map((item,i)=>{
                return(
                    <div style={{margin:10,border:"1px solid gray",padding:10,textAlign:"center"}}>
                        <label style={{fontSize:18}}>{item.name} <span style={{color:"red",fontSize:15}}>({item.value})</span></label>
                        <Button 
                        onClick={()=>{this.setState({selectedLco:item,isShowTransferWarning:true})}}
                        style={{fontSize:14,fontWeight:"bold",color:"white",backgroundColor:"green",borderColor:"green",marginLeft:15}}>Select</Button>
                    </div>
                );
            })}
            </div>
          );
      }else{
          return(
            <Undraw
            style={{ marginTop: 20, display: 'block', width: 300, marginBottom: 10, height: 150,marginLeft:"-10px" }}
            name="cancel" />
          );
      }
  }
    render() {
        return (
            <div>


                <Drawer

                    open={this.props.isShowLogOutWarning}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card >


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18,   }}>Do you want <span style={{ color: "red", fontWeight:"bold" }}>Log Out</span> ?</div>
                            <Undraw  className="undrawimg"     name="monitor"/>
                            
                            <Button
                                style={{backgroundColor:"red",borderColor:"red"}}
                                className="mt-3 pull-right"
                                onClick={() => {

                                    this.props.hindLogOutDialog();

                                }}
                            >cancel</Button>
                            <Button
                                 style={{backgroundColor:"green",borderColor:"green",marginRight:15}}
                                 className="mt-3 pull-right"
                                onClick={() => {
                    
                                    this.props.logOut();

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

                    open={this.props.isShowLcoSearchDialog}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card  style={{maxWidth:"80vh"}}>


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18,textAlign:"center"   }}> Partner Search</div>
                            <input
                            style={{marginLeft:"29%",marginTop:10,marginBottom:10,border:"2px solid black",padding:5,alignItems:"center",justifyContent:"center"}}
                            placeholder="Partner Search......"
                            onChange={(e)=>{this.props.lcoSearchFunc(e.target.value)}}
                            />
                            {this.isShowLcoSearchResult()}
                            
                            <Button
                                 style={{backgroundColor:"red",borderColor:"red",marginRight:15}}
                                 className="mt-3 pull-right"
                                onClick={() => {
                    
                                    this.props.hindLcoSearchDialog();

                                }}
                            >Cancel</Button>



                        </Card.Body>
                    </Card>

                </Drawer>
                <Drawer

                    open={this.state.isShowTransferWarning}
                    onRequestClose={this.toggle}
                    direction='left'
                >

                    <Card  style={{maxWidth:"80vh"}}>


                        <Card.Body className="pl-3 pr-3">
                            <div style={{ fontSize: 18 }}> Transfer STB</div>
                            <div style={{marginLeft:"30px",marginTop:20}}> 
                             <div>
                                 <label style={{fontSize:15}}>LCO name : <span style={{fontWeight:"bold",color:"red"}}>{this.state.selectedLco.name}</span></label>
                                
                             </div>
                             <div>
                                 <label style={{fontSize:15}}>LCO code : <span style={{fontWeight:"bold",color:"red"}}>{this.state.selectedLco.value}</span></label>
                                
                             </div>
                             <div>
                                 <label style={{fontSize:15}}>No of STB : <span style={{fontWeight:"bold",color:"red"}}>{this.props.selectedSTB.length}</span></label>
                                
                             </div>
                            </div>
                            <Undraw
            style={{ marginTop: 20, display: 'block', width: 300, marginBottom: 10, height: 150,marginLeft:"-10px" }}
            name="connected" />
                          
                            
                            
                            <Button
                                style={{backgroundColor:"red",borderColor:"red"}}
                                className="mt-3 pull-right"
                                onClick={() => {

                                   this.setState({isShowTransferWarning:false})

                                }}
                            >cancel</Button>
                            <Button
                                 style={{backgroundColor:"green",borderColor:"green",marginRight:15}}
                                 className="mt-3 pull-right"
                                onClick={() => {
                                    this.setState({isShowTransferWarning:false});
                                    this.props.transferFunc(this.state.selectedLco.value);

                                }}
                            >OK</Button>



                        </Card.Body>
                    </Card>

                </Drawer>

                <Drawer

open={this.props.isShowProcessDialog}
onRequestClose={this.toggle}
direction='left'
>

<Card >


    <Card.Body className="pl-3 pr-3">

        <div style={{ fontSize: 18, marginLeft: 20, color: "red", fontWeight: "bold",textAlign:"center" }}>
            {this.props.noOfcompletedData}
            <span style={{ fontSize: 18, color: "black" }}> of </span>
            {this.props.noOfDataGoingToProcess}
            <span style={{ fontSize: 18, color: "black" }}> is Completed Successfully </span>
        </div>
        <Undraw
            style={{ marginTop: 10, display: 'block', width: 461, marginBottom: 10, height: 263 }}
            name= {this.props.noOfcompletedData == this.props.noOfDataGoingToProcess ?"new-message":"design-process"} />

        {this.showOkBtnInProcessing()}





    </Card.Body>
</Card>

</Drawer>

<Drawer

open={this.props.isShowUnProcessedDataListDialog}
onRequestClose={this.toggle}
direction='left'
>

<Card >


    <Card.Body className="pl-3 pr-3">
        <div style={{ fontSize: 18, fontWeight: "bold",color:"red" }}>Unable To process this STB List:</div>

        <div style={{marginLeft:50,maxHeight:"30vh",marginRight:30,color:"rgb(26, 172, 195)",overflow:"auto"}}>
            {this.props.unProcessedData.map((data,i)=>{
                return(
                    <div style={{fontSize:15,marginTop:5,fontWeight:"bold"}}> {i+1}.)  {data.result}</div>
                )
            })}
        </div>


        <Button
            style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
            className="mt-3 pull-right"
            onClick={() => {

                this.props.hindUnProcessedDataListDialog();

            }}
        >OK</Button>



    </Card.Body>
</Card>


</Drawer>


<Drawer

open={this.props.isEmptyList}
onRequestClose={this.toggle}
direction='left'
>

<Card >


    <Card.Body className="pl-3 pr-3">
        <div style={{ fontSize: 18, fontWeight: "bold",color:"red" }}>Please select STB </div>

        <Undraw
            style={{ marginTop: 10, display: 'block', width: 461, marginBottom: 10, height: 263 }}
            name= "empty" />


        <Button
            style={{ backgroundColor: "green", borderColor: "green", marginRight: 15 }}
            className="mt-3 pull-right"
            onClick={() => {

                this.props.hindEmptyList();

            }}
        >OK</Button>



    </Card.Body>
</Card>

</Drawer>


<Drawer

open={this.props.isShowTransferWarningCSV}
onRequestClose={this.toggle}
direction='left'
>

<Card  style={{maxWidth:"80vh"}}>


    <Card.Body className="pl-3 pr-3">
        <div style={{ fontSize: 18 }}> Transfer STB</div>
        <div style={{marginLeft:"30px",marginTop:10}}> Do you want <span style={{fontWeight:"bold",color:"red"}}> Transfer the STB</span> ?</div>
       
        <Undraw
style={{ marginTop: 20, display: 'block', width: 300, marginBottom: 10, height: 150,marginLeft:"-10px" }}
name="connected" />
      
        
        
        <Button
            style={{backgroundColor:"red",borderColor:"red"}}
            className="mt-3 pull-right"
            onClick={() => {

               this.props.hindTransferWarningCSV();

            }}
        >cancel</Button>
        <Button
             style={{backgroundColor:"green",borderColor:"green",marginRight:15}}
             className="mt-3 pull-right"
            onClick={() => {
                this.props.hindTransferWarningCSV();
                this.props.transferFuncCSV()


            }}
        >OK</Button>



    </Card.Body>
</Card>

</Drawer>






            </div>
        );
    }
}